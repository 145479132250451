export default {
    'redeemCoupon.title': '兌換票券',
    'redeemCoupon.message.one': '將扣除',
    'redeemCoupon.message.two': '點並轉為',
    'redeemCoupon.unit': '張',
    'redeemCoupon.exchange_success': '兌換成功',
    'redeemCoupon.exchange_fail': '兌換失敗',
    'redeemCoupon.no_service.one': '目前尚無提供票券兌換服務',
    'redeemCoupon.no_service.two': '如有需求請洽品牌客服',
    'redeemCoupon.button': '兌換',
    'redeemCoupon.ok_text.one': '是',
    'redeemCoupon.ok_text.two': '好',
    'redeemCoupon.getting_time': '領券時間',
    'redeemCoupon.time.start': '起',
    'redeemCoupon.time.end': '止',
} as Record<string, string>;
