import addOA from './addOA';
import batchDeduct from './batchDeduct';
import cart from './cart';
import collectPoint from './collectPoint';
import components from './components';
import coupon from './coupon';
import home from './home';
import invoiceCarrier from './invoiceCarrier';
import me from './me';
import memberArea from './memberArea';
import memberAreaCouponHistory from './memberAreaCouponHistory';
import memberAreaJourney from './memberAreaJourney';
import memberAreaPointsHistory from './memberAreaPointsHistory';
import memberBenefit from './memberBenefit';
import memberCard from './memberCard';
import memberPoint from './memberPoint';
import modifyProfile from './modifyProfile';
import onlineStore from './onlineStore';
import paymentHistory from './paymentHistory';
import pointCard from './pointCard';
import pointsHistory from './pointsHistory';
import privacy from './privacy';
import product from './product';
import redeemCoupon from './redeemCoupon';
import redeemPoint from './redeemPoint';
import register from './register';
import registerBenefit from './registerBenefit';
import returnInstruction from './returnInstruction';
import searchProduct from './searchProduct';
import shareCoupon from './shareCoupon';
import shoppingNote from './shoppingNote';
import storeOrders from './storeOrders';
import terms from './terms';
import transactions from './transactions';

export default {
  ...addOA,
  ...batchDeduct,
  ...cart,
  ...collectPoint,
  ...components,
  ...coupon,
  ...home,
  ...invoiceCarrier,
  ...me,
  ...memberArea,
  ...memberAreaCouponHistory,
  ...memberAreaJourney,
  ...memberAreaPointsHistory,
  ...memberBenefit,
  ...memberCard,
  ...memberPoint,
  ...modifyProfile,
  ...onlineStore,
  ...paymentHistory,
  ...pointCard,
  ...pointsHistory,
  ...privacy,
  ...product,
  ...redeemCoupon,
  ...redeemPoint,
  ...register,
  ...registerBenefit,
  ...returnInstruction,
  ...searchProduct,
  ...shareCoupon,
  ...shoppingNote,
  ...storeOrders,
  ...terms,
  ...transactions,
} as Record<string, string>;
