export default {
  'batchDeduct.batchCode.title': '核銷碼',
  'batchDeduct.batchCode.warning': '限本人使用，翻拍或截圖使用無效',
  'batchDeduct.batchCode.code': '本次核銷內容',
  'batchDeduct.batchCode.counts': '張',
  'batchDeduct.batchCode.copySuccess': '複製核銷碼成功',
  'batchDeduct.batchCode.success': '核銷成功',
  'batchDeduct.batchCode.produce_fail': '產生核銷碼失敗',
  'batchDeduct.title': '批次核銷',
  'batchDeduct.error.stamp_no_permission': '印章無核銷以下票券權限',
  'batchDeduct.cancel_all': '全部取消',
  'batchDeduct.partial_deduct': '部分核銷',
  'batchDeduct.message.following_ticket_deduct_success': '以下票券核銷成功',
} as Record<string, string>;
