export default {
  'cart.title': 'ショッピングカート',
  'cart.order_info': '注文情報',
  'cart.action.delete': '削除',
  'cart.payment_status.unpaid': '未払い',
  'cart.payment_status.fail': '支払い失敗',
  'cart.error.out_of_stock': '商品が在庫切れです',
  'cart.error.bundle_out_of_stock': 'バンドル商品の在庫が不足しています',
  'cart.error.product_removed': '商品が販売終了しました',
  'cart.error.checkout_fail': '決済に失敗しました',
  'cart.empty_text.one': 'カートは空です',
  'cart.empty_text.two': '他の商品を見てみましょう',
  'cart.go_to_checkout': '決済に進む',
  'cart.keep_shopping': '買い物を続ける',
  'cart.check_order_status': '注文状況を確認する',
  'cart.return': '戻る',
  'cart.buyer_info': '注文者情報',
  'cart.buyer_info.error.phone_required': '連絡先電話番号を入力してください',
  'cart.buyer_info.error.phone_formatted': '連絡先電話番号の形式が正しくありません',
  'cart.buyer_info.error.location_required': '都道府県を選択してください',
  'cart.buyer_info.error.invoice_carrier.phone.required': '携帯バーコードを入力してください',
  'cart.buyer_info.error.invoice_carrier.phone.formatted': '携帯バーコードの形式が正しくありません',
  'cart.buyer_info.error.invoice_carrier.company.required': '法人番号を入力してください',
  'cart.buyer_info.error.invoice_carrier.company.formatted': '法人番号は8桁の数字である必要があります',
  'cart.buyer_info.error.invoice_carrier.company.verify': '法人番号の検証に失敗しました',
  'cart.buyer_info.error.invoice_carrier.company_title.required': '会社名を入力してください',
  'cart.buyer_info.contact_phone': '連絡先電話番号',
  'cart.buyer_info.residential_area': '居住都道府県',
  'cart.buyer_info.location': '市区町村',
  'cart.buyer_info.address': '住所',
  'cart.buyer_info.invoice_carrier': '電子請求書キャリア',
  'cart.buyer_info.invoice_carrier.choose': 'キャリアを選択',
  'cart.buyer_info.company.title': '会社名',
} as Record<string, string>;
