export default {
  'memberPoint.title': '会員ポイント',
  'memberPoint.detail': 'ポイント明細',
  'memberPoint.store': '店舗',
  'memberPoint.point': 'ポイント',
  'memberPoint.current_point': '現在の累積ポイント',
  'memberPoint.issue_date': 'ポイント発行日',
  'memberPoint.case': 'イベント',
  'memberPoint.receiver': '受領者',
  'memberPoint.transfer': '譲渡者',
} as Record<string, string>;