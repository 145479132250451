export default {
  'onlineStore.store': '商店',
  'onlineStore.coming_soon': '即將開賣',
  'onlineStore.piece': '張',
  'onlineStore.original_price': '原價',
  'onlineStore.text.building': '內容建構中',
  'onlineStore.text.more_information': '更多資訊請查看品牌官網/社群',
  'onlineStore.category': '分類',
  'onlineStore.brand': '品牌',
  'onlineStore.recommend': '推薦',
  'onlineStore.package': '套票',
  'onlineStore.my_product': '我的商品券',
  'onlineStore.12cm.company_name': '睿鼎數位股份有限公司',
} as Record<string, string>;
