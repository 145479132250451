export default {
  'home.title': '',
  'home.event': '',
  'home.news': '',
  'home.event_information': '',
  'home.announcement': '',
  'home.goto': '',
  'home.latest_events': '',
  'home.more_events': '',
} as Record<string, string>;
