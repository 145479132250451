export default {
    'storeOrders.title': '',
    'storeOrders.last_three_months': '',
    'storeOrders.last_six_months': '',
    'storeOrders.last_one_year': '',
    'storeOrders.filter_state_category.one': '',
    'storeOrders.filter_state_category.two': '',
    'storeOrders.filter_state_category.three': '',
    'storeOrders.filter_state_category.four': '',
    'storeOrders.order.no': '',
    'storeOrders.order.amount': '',
    'storeOrders.order.status': '',
    'storeOrders.order.payment_status': '',
    'storeOrders.order.date': '',
    'storeOrders.order.cancel_date': '',
    'storeOrders.order.detail': '',
    'storeOrders.search_order.placeholder': '',
    'storeOrders.empty': '',
    'storeOrders.carrier.phone': '',
    'storeOrders.carrier.member': '',
    'storeOrders.carrier.company': '',
    'storeOrders.order_info': '',
    'storeOrders.order_refund_date': '',
    'storeOrders.refund_amount': '',
    'storeOrders.credit_card_last_four_digits': '',
    'storeOrders.contact_number': '',
    'storeOrders.email': '',
    'storeOrders.company_header': '',
    'storeOrders.order_detail': '',
    'storeOrders.order_code': '',
    'storeOrders.close': '',
    'storeOrders.search_fail': '',
    'storeOrders.text.research': '',
    'storeOrders.retry': '',
} as Record<string, string>;
