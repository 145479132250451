export default {
  'paymentHistory.time.last_three_months': '最近三個月',
  'paymentHistory.time.last_six_months': '最近六個月',
  'paymentHistory.time.last_one_year': '最近一年',
  'paymentHistory.status.completed': '已完成',
  'paymentHistory.status.canceled': '已取消',
  'paymentHistory.status.partial_canceled': '部分取消',
  'paymentHistory.title': '交易記錄',
  'paymentHistory.detail': '交易明細',
  'paymentHistory.update_time': '異動時間',
  'paymentHistory.total_amount': '總金額',
  'paymentHistory.store': '門市',
  'paymentHistory.status': '狀態',
  'paymentHistory.level_progress_text.from': '距離成為',
  'paymentHistory.level_progress_text.level': '等級',
  'paymentHistory.level_progress_text.need': '再消費',
  'paymentHistory.level_progress_text.or': '或',
  'paymentHistory.level_progress_text.upon': '元以上',
  'paymentHistory.level_progress_text.time': '次',
  'paymentHistory.period_amount': '效期內累計消費',
  'paymentHistory.member_total_amount': '會員總累計消費',
  'paymentHistory.dollar': '元',
  'paymentHistory.zero_dollar': '0 元',
  'paymentHistory.trade_time': '交易時間',
  'paymentHistory.empty': '無資料',
  'paymentHistory.name': '名稱',
  'paymentHistory.amount': '金額',
  'paymentHistory.cancel_date': '取消時間',
} as Record<string, string>;
