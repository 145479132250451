export default {
  'pointCard.title': '',
  'pointCard.welcome': '',
  'pointCard.my_card': '',
  'pointCard.all_events': '',
  'pointCard.event_period': '',
  'pointCard.exchange_period': '',
  'pointCard.specified_store': '',
  'pointCard.join': '',
  'pointCard.event_end': '',
  'pointCard.add_success': '',
  'pointCard.event_deadline': '',
  'pointCard.detail': '',
} as Record<string, string>;
