export default {
    'product.error.over_maximum_quantity': '',
    'product.error.sold_out': '',
    'product.error.add_cart_fail': '',
    'product.available_store': '',
    'product.all': '',
    'product.introduction': '',
    'product.discount_period': '',
    'product.sale_period': '',
    'product.every_set': '',
    'product.piece': '',
    'product.remain_count': '',
    'product.notice': '',
    'product.publisher.title': '',
    'product.publisher.name': '',
    'product.publisher.address': '',
    'product.publisher.manager': '',
    'product.contract.title': '',
    'product.contract.content.one': '',
    'product.contract.content.two': '',
    'product.return_guide': '',
    'product.shopping_guide': '',
    'product.expired': '',
    'product.coming_soon': '',
    'product.add_to_cart': '',
    'product.ok': '',
} as Record<string, string>;
