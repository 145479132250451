export default {
  'memberAreaJourney.title': '会員履歴',
  'memberAreaJourney.period': '有効期間',
  'memberAreaJourney.no_records': '会員履歴の記録はまだありません',
  'memberAreaJourney.reason_1': '新規会員',
  'memberAreaJourney.reason_2': '消費ランクアップ',
  'memberAreaJourney.reason_3': '手動ランクアップ',
  'memberAreaJourney.reason_4': '手動ランクダウン',
  'memberAreaJourney.reason_5': '期限切れランクダウン',
  'memberAreaJourney.reason_6': '期限切れランクアップ',
  'memberAreaJourney.reason_7': '取引キャンセルによるランクダウン',
  'memberAreaJourney.reason_8': '期限切れ指定ランクダウン',
  'memberAreaJourney.reason_9': '期限切れ指定ランクアップ',
  'memberAreaJourney.reason_10': '新しい会員システムの導入',
} as Record<string, string>;
