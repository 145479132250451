export default {
    'redeemCoupon.title': '',
    'redeemCoupon.message.one': '',
    'redeemCoupon.message.two': '',
    'redeemCoupon.unit': '',
    'redeemCoupon.exchange_success': '',
    'redeemCoupon.exchange_fail': '',
    'redeemCoupon.no_service.one': '',
    'redeemCoupon.no_service.two': '',
    'redeemCoupon.button': '',
    'redeemCoupon.ok_text.one': '',
    'redeemCoupon.ok_text.two': '',
    'redeemCoupon.getting_time': '',
    'redeemCoupon.time.start': '',
    'redeemCoupon.time.end': '',
} as Record<string, string>;
