export default {
  'components.DatePicker.clear': 'クリア',
  'components.DatePicker.cancel': 'キャンセル',
  'components.DatePicker.choose': '日付を選択してください',
  'components.DatePicker.confirm': '確認',
  'components.Dialog.no': 'いいえ',
  'components.LoginFailed.title': 'ログイン失敗',
  'components.LoginFailed.notice': 'このページを閉じて再度開いてください',
  'components.Maintenance.title': 'システムメンテナンス中',
  'components.Maintenance.notice': '後でページをリフレッシュして再試行してください',
  'components.Maintenance.refresh': 'リフレッシュ',
  'components.MenuTabBar.ticket': 'チケット',
  'components.MenuTabBar.my': 'マイページ',
  'components.MenuTabBar.menu_item.more': 'もっと見る',
  'components.MenuTabBar.menu_item.home': 'ホーム',
  'components.MenuTabBar.menu_item.member_card': '会員カード',
  'components.MenuTabBar.menu_item.coupon': 'クーポン',
  'components.MenuTabBar.menu_item.store': '店舗',
  'components.MenuTabBar.menu_item.loyalty_card': 'ポイントカード',
  'components.MenuTabBar.menu_item.member_area': '会員専用エリア',
  'components.NotFound.title': 'この店舗情報は見つかりません',
  'components.NotFound.notice': '下のボタンをクリックして再検索を試みてください',
  'components.OpenInLINE.browser_invalid': 'このブラウザではEchoss VIPを使用できません',
  'components.OpenInLINE.guide.title': '以下の手順に従って設定してください：',
  'components.OpenInLINE.guide.step.one': '1. LINEアプリの「設定」を開く',
  'components.OpenInLINE.guide.step.two': '2. 「LINE Labs」に進む',
  'components.OpenInLINE.guide.step.three':
    '3. 「リンクをデフォルトのブラウザで開く」の項目をオフにする',
  'components.OpenInLINE.guide.finish': '設定後、再度リンクをクリックして使用できます',
  'components.OTPPhone.ManualOTPDialog.enter_verification_code':
    '店舗から提供された4桁の認証コードを入力してください',
  'components.OTPPhone.verification_fail': '認証に失敗しました',
  'components.OTPPhone.resend_seconds': '秒後に再送信',
  'components.OTPPhone.send_verification_code': '認証コードを送信',
  'components.OTPPhone.auth_code.required': '認証コードを入力してください',
  'components.OTPPhone.message_unreceived': 'SMSが届いていませんか？',
  'components.OTPPhone.message_auth_code': 'SMS認証コード',
  'components.OTPPhone.verified': '認証済み',
} as Record<string, string>;
