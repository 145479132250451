export default {
  'cart.title': '',
  'cart.order_info': '',
  'cart.action.delete': '',
  'cart.payment_status.unpaid': '',
  'cart.payment_status.fail': '',
  'cart.error.out_of_stock': '',
  'cart.error.bundle_out_of_stock': '',
  'cart.error.product_removed': '',
  'cart.error.checkout_fail': '',
  'cart.empty_text.one': '',
  'cart.empty_text.two': '',
  'cart.go_to_checkout': '',
  'cart.keep_shopping': '',
  'cart.check_order_status': '',
  'cart.return': '',
  'cart.buyer_info': '',
  'cart.buyer_info.error.phone_required': '',
  'cart.buyer_info.error.phone_formatted': '',
  'cart.buyer_info.error.location_required': '',
  'cart.buyer_info.error.invoice_carrier.phone.required': '',
  'cart.buyer_info.error.invoice_carrier.phone.formatted': '',
  'cart.buyer_info.error.invoice_carrier.company.required': '',
  'cart.buyer_info.error.invoice_carrier.company.formatted': '',
  'cart.buyer_info.error.invoice_carrier.company.verify': '',
  'cart.buyer_info.error.invoice_carrier.company_title.required': '',
  'cart.buyer_info.contact_phone': '',
  'cart.buyer_info.residential_area': '',
  'cart.buyer_info.location': '',
  'cart.buyer_info.address': '',
  'cart.buyer_info.invoice_carrier': '',
  'cart.buyer_info.invoice_carrier.choose': '',
  'cart.buyer_info.company.title': '',
} as Record<string, string>;
