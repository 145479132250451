export default {
  'paymentHistory.time.last_three_months': '',
  'paymentHistory.time.last_six_months': '',
  'paymentHistory.time.last_one_year': '',
  'paymentHistory.status.completed': '',
  'paymentHistory.status.canceled': '',
  'paymentHistory.status.partial_canceled': '',
  'paymentHistory.title': '',
  'paymentHistory.detail': '',
  'paymentHistory.update_time': '',
  'paymentHistory.total_amount': '',
  'paymentHistory.store': '',
  'paymentHistory.status': '',
  'paymentHistory.level_progress_text.from': '',
  'paymentHistory.level_progress_text.level': '',
  'paymentHistory.level_progress_text.need': '',
  'paymentHistory.level_progress_text.or': '',
  'paymentHistory.level_progress_text.upon': '',
  'paymentHistory.level_progress_text.time': '',
  'paymentHistory.period_amount': '',
  'paymentHistory.member_total_amount': '',
  'paymentHistory.dollar': '',
  'paymentHistory.zero_dollar': '',
  'paymentHistory.trade_time': '',
  'paymentHistory.empty': '',
  'paymentHistory.name': '',
  'paymentHistory.amount': '',
  'paymentHistory.cancel_date': '',
} as Record<string, string>;
