export default {
    'pointHistory.title': '點數記錄',
    'pointHistory.event.not_match': '不符發點',
    'pointHistory.event.collect': '集點',
    'pointHistory.event.exchange': '兌換',
    'pointHistory.event.send': '發點',
    'pointHistory.event.deduct': '扣點',
    'pointHistory.event.pos_send': 'POS發點',
    'pointHistory.event.pos_deduct': 'POS扣點',
    'pointHistory.event.transfer': '轉贈',
    'pointHistory.event.receive': '受贈',
    'pointHistory.message.cancel_success': '取消集點成功',
    'pointHistory.message.cancel_fail': '取消集點成功',
    'pointHistory.current_points': '目前點數',
    'pointHistory.expiration_date': '到期日',
    'pointHistory.button.cancel': '取消',
    'pointHistory.empty': '無資料',
    'pointHistory.point_detail': '點數詳情',
    'pointHistory.processing_time': '處理時間',
    'pointHistory.remark': '備註',
    'pointHistory.consumption_amount': '消費金額',
} as Record<string, string>;
