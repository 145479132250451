export default {
    'transactions.title': '交易記錄',
    'transactions.empty': '無資料',
    'transactions.order.no': '訂單編號',
    'transactions.order.status': '訂單狀態',
    'transactions.order.purchase_date': '購買日期',
    'transactions.order.item': '購買商品',
    'transactions.order.amount': '支付金額',
    'transactions.order.payment': '支付方式',
    'transactions.order.credit_card': '信用卡',
    'transactions.last_month': '最近一個月',
    'transactions.purchase_complete': '購買完成',
    'transactions.view_tickets': '查看票券',
    'transactions.trade_record': '交易紀錄',
} as Record<string, string>;
