export default {
    'transactions.title': '',
    'transactions.empty': '',
    'transactions.order.no': '',
    'transactions.order.status': '',
    'transactions.order.purchase_date': '',
    'transactions.order.item': '',
    'transactions.order.amount': '',
    'transactions.order.payment': '',
    'transactions.order.credit_card': '',
    'transactions.last_month': '',
    'transactions.purchase_complete': '',
    'transactions.view_tickets': '',
    'transactions.trade_record': '',
} as Record<string, string>;
