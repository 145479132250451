export default {
  'paymentHistory.time.last_three_months': '過去3か月',
  'paymentHistory.time.last_six_months': '過去6か月',
  'paymentHistory.time.last_one_year': '過去1年間',
  'paymentHistory.status.completed': '完了',
  'paymentHistory.status.canceled': 'キャンセル済み',
  'paymentHistory.status.partial_canceled': '一部キャンセル',
  'paymentHistory.title': '取引履歴',
  'paymentHistory.detail': '取引明細',
  'paymentHistory.update_time': '更新時間',
  'paymentHistory.total_amount': '総額',
  'paymentHistory.store': '店舗',
  'paymentHistory.status': 'ステータス',
  'paymentHistory.level_progress_text.from': 'になるまで',
  'paymentHistory.level_progress_text.level': 'ランク',
  'paymentHistory.level_progress_text.need': 'あと消費',
  'paymentHistory.level_progress_text.or': 'または',
  'paymentHistory.level_progress_text.upon': '元以上',
  'paymentHistory.level_progress_text.time': '回',
  'paymentHistory.period_amount': '有効期間内の累計消費',
  'paymentHistory.member_total_amount': '会員総累計消費',
  'paymentHistory.dollar': '円',
  'paymentHistory.zero_dollar': '0 元',
  'paymentHistory.trade_time': '取引時間',
  'paymentHistory.empty': 'データなし',
  'paymentHistory.name': '名称',
  'paymentHistory.amount': '金額',
  'paymentHistory.cancel_date': 'キャンセル日時',
} as Record<string, string>;
