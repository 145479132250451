export default {
  'batchDeduct.batchCode.title': '検証コード',
  'batchDeduct.batchCode.warning': '本人のみ使用可能、撮影やスクリーンショットでの使用は無効です',
  'batchDeduct.batchCode.code': '今回の検証内容',
  'batchDeduct.batchCode.counts': '枚',
  'batchDeduct.batchCode.copySuccess': '検証コードのコピーに成功しました',
  'batchDeduct.batchCode.success': '検証が成功しました',
  'batchDeduct.batchCode.produce_fail': '検証コードの生成に失敗しました',
  'batchDeduct.title': 'バッチ検証',
  'batchDeduct.error.stamp_no_permission': 'スタンプは以下のクーポンを検証する権限がありません',
  'batchDeduct.cancel_all': 'すべてキャンセル',
  'batchDeduct.partial_deduct': '一部検証',
  'batchDeduct.message.following_ticket_deduct_success': '以下のクーポンが正常に検証されました',
} as Record<string, string>;