export default {
  'register.alert.confirm': '',
  'register.alert.register_fail': '',
  'register.alert.phone_number.exists': '',
  'register.alert.phone_number.verify_fail': '',
  'register.alert.email.exists': '',
  'register.alert.email.verify_fail': '',
  'register.alert.birthday.verify_fail': '',
  'register.alert.gender.verify_fail': '',
  'register.alert.city.verify_fail': '',
  'register.alert.job.verify_fail': '',
  'register.alert.education_level.verify_fail': '',
  'register.alert.marriage.verify_fail': '',
  'register.alert.interest.verify_fail': '',
  'register.alert.diet.verify_fail': '',
  'register.alert.line_account.registered': '',
  'register.alert.otp.verify_fail': '',
  'register.alert.field_required': '',
  'register.alert.please_select': '',
  'register.alert.number_required': '',
  'register.alert.number_length': '',
  'register.phone_number': '',
  'register.phone_number.required': '',
  'register.phone_number.format_error': '',
  'register.phone_number.not_match': '',
  'register.phone_number.enter_again': '',
  'register.name': '',
  'register.name.required': '',
  'register.name.format_error': '',
  'register.birth': '',
  'register.birth.required': '',
  'register.email': '',
  'register.email.required': '',
  'register.email.format_error': '',
  'register.gender': '',
  'register.gender.required': '',
  'register.city': '',
  'register.city.required': '',
  'register.job': '',
  'register.job.required': '',
  'register.eduction_level': '',
  'register.eduction_level.required': '',
  'register.marriage': '',
  'register.marriage.required': '',
  'register.habit': '',
  'register.habit.required': '',
  'register.diet': '',
  'register.diet.required': '',
  'register.agree': '',
  'register.term': '',
  'register.account_verification': '',
  'register.member_information': '',
  'register.complete': '',
  'register.country_code': '',
  'register.country_code.required': '',
} as Record<string, string>;
