import { echossAxios } from './index';

export async function getShortUri(token: string, shortUri: string) {
  try {
    const { data } = await echossAxios.request({
      url: `/api/url/${shortUri}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return data.url;
  } catch (e) {
    console.error(e);
    return '';
  }
}
