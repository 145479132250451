export default {
  'coupon.title': '',
  'coupon.content': '',
  'coupon.record': '',
  'coupon.discount': '',
  'coupon.product': '',
  'coupon.balance': '',
  'coupon.select_redeem_number': '',
  'coupon.error.redeem_fail': '',
  'coupon.error.redeem_fail.not_shop': '',
  'coupon.error.redeem_fail.not_shop_group': '',
  'coupon.error.redeem_fail.shop_not_found': '',
  'coupon.error.redeem_fail.store_not_found': '',
  'coupon.error.redeem_fail.coupon_invalid': '',
  'coupon.error.redeem_fail.remaining_not_enough': '',
  'coupon.applicable_store': '',
  'coupon.use_period': '',
  'coupon.quantity_held': '',
  'coupon.transfer': '',
  'coupon.not_enabled': '',
  'coupon.period_is': '',
  'coupon.barcode': '',
  'coupon.guide': '',
  'coupon.choose_redeem_quantity': '',
  'coupon.empty.product': '',
  'coupon.empty.ticket': '',
  'coupon.empty.balance': '',
  'coupon.welcome': '',
  'coupon.redeemable': '',
  'coupon.not_start': '',
  'coupon.redeem_immediately': '',
  'coupon.notification_time': '',
  'coupon.receive_time': '',
  'coupon.giver': '',
  'coupon.copy_success': '',
  'coupon.redeem_coupon': '',
  'coupon.redeem_store': '',
  'coupon.not_start_yet': '',
  'coupon.redeem_count': '',
  'coupon.redeemed': '',
  'coupon.expired': '',
  'coupon.voided': '',
  'coupon.receive': '',
  'coupon.balance_amount': '',
  'coupon.using': '',
  'coupon.not_used': '',
  'coupon.use_record': '',
  'coupon.use_amount': '',
  'coupon.use_time': '',
  'coupon.use_store': '',
  'coupon.ticket_serial_number': '',
  'coupon.remaining_amount': '',
  'coupon.enter_use_amount': '',
  'coupon.cancel_transfer_success': '',
  'coupon.cancel_transfer_fail': '',
  'coupon.obtain_time': '',
  'coupon.gift': '',
  'coupon.issuer': '',
  'coupon.redeem_time': '',
  'coupon.redeem_status': '',
  'coupon.redeem_no': '',
  'coupon.disable_time': '',
  'coupon.account_status': '',
  'coupon.overdue_time': '',
  'coupon.cancel_transfer': '',
  'coupon.count_number': '',
  'coupon.used_amount': '',
  'coupon.recent_use_time': '',
  'coupon.recent_use_store': '',
} as Record<string, string>;
