import { Button, CircularProgress } from '@mui/material';
import type { FC } from 'react';

import { ReactComponent as Stamp } from '../../assets/img_stamp.svg';
import FormattedMessage from '../FormattedMessage';
import styles from './index.module.scss';
type MaintenancePropsType = {
  retry?: boolean;
  retryFn?: () => Promise<void>;
  loading?: boolean;
};

const Maintenance: FC<MaintenancePropsType> = ({ retry = true, retryFn, loading }) => {
  return (
    <div className={styles.notFound}>
      <Stamp />
      <div className={styles.description}>
        <p>{FormattedMessage('components.Maintenance.title')}</p>
        {retry && <p>{FormattedMessage('components.Maintenance.notice')}</p>}
      </div>
      {retry && (
        <div className={styles.btnContainer}>
          <Button
            onClick={() => {
              if (retryFn) {
                retryFn();
              }
            }}
            className={loading ? styles.loading : ''}
            disabled={loading}
          >
            {loading && (
              <div className={styles.loading}>
                <CircularProgress size={30} color="primary" />
              </div>
            )}
            {FormattedMessage('components.Maintenance.refresh')}
          </Button>
        </div>
      )}
    </div>
  );
};
export default Maintenance;
