export default {
  'cart.title': '購物車',
  'cart.order_info': '訂購資訊',
  'cart.action.delete': '刪除',
  'cart.payment_status.unpaid': '尚未付款',
  'cart.payment_status.fail': '付款失敗',
  'cart.error.out_of_stock': '商品已無庫存',
  'cart.error.bundle_out_of_stock': '套票商品庫存不足',
  'cart.error.product_removed': '商品已下架',
  'cart.error.checkout_fail': '結帳失敗',
  'cart.empty_text.one': '您的購物車是空的',
  'cart.empty_text.two': '再去逛逛吧',
  'cart.go_to_checkout': '前往結帳',
  'cart.keep_shopping': '繼續購物',
  'cart.check_order_status': '查看訂單狀態',
  'cart.return': '返回',
  'cart.buyer_info': '訂購人資訊',
  'cart.buyer_info.error.phone_required': '請輸入聯絡電話',
  'cart.buyer_info.error.phone_formatted': '聯絡電話格式錯誤',
  'cart.buyer_info.error.location_required': '請先選擇縣市',
  'cart.buyer_info.error.invoice_carrier.phone.required': '請輸入手機條碼',
  'cart.buyer_info.error.invoice_carrier.phone.formatted': '手機條碼 格式錯誤',
  'cart.buyer_info.error.invoice_carrier.company.required': '請輸入統一編號',
  'cart.buyer_info.error.invoice_carrier.company.formatted': '統一編號 須為8碼數字',
  'cart.buyer_info.error.invoice_carrier.company.verify': '統一編號 驗證錯誤',
  'cart.buyer_info.error.invoice_carrier.company_title.required': '請輸入公司抬頭',
  'cart.buyer_info.contact_phone': '聯絡電話',
  'cart.buyer_info.residential_area': '居住縣市',
  'cart.buyer_info.location': '鄉鎮市區',
  'cart.buyer_info.address': '地址',
  'cart.buyer_info.invoice_carrier': '電子發票載具',
  'cart.buyer_info.invoice_carrier.choose': '選擇載具',
  'cart.buyer_info.company.title': '公司抬頭',
} as Record<string, string>;
