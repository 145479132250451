export default {
    'product.error.over_maximum_quantity': '1回の購入数量の上限を超えました',
    'product.error.sold_out': 'この商品は完売しました',
    'product.error.add_cart_fail': 'カートへの追加に失敗しました',
    'product.available_store': '対応店舗',
    'product.all': 'すべて',
    'product.introduction': '商品紹介',
    'product.discount_period': '割引期間',
    'product.sale_period': '販売期間',
    'product.every_set': '各セット',
    'product.piece': '枚',
    'product.remain_count': '残り数量',
    'product.notice': '注意事項',
    'product.publisher.title': '発行者情報',
    'product.publisher.name': '睿鼎デジタル株式会社',
    'product.publisher.address': '住所: 台北市大安区仁愛路4段25号3階',
    'product.publisher.manager': '責任者: 陳衍彰',
    'product.contract.title': '契約保証',
    'product.contract.content.one': '本券の販売で受け取った額面金額は、発行者が永豊商業銀行に開設した代金保管専用口座に預け入れられ、専用として使用されます。「専用」とは、発行者が商品の引渡しまたはサービス提供の義務を履行するために使用されることを意味します。',
    'product.contract.content.two': '保管期間は電子券販売日（券面に記載された販売日を基準とします）から1年間です。',
    'product.return_guide': '返品・返金に関する説明',
    'product.shopping_guide': 'ショッピングガイド',
    'product.expired': 'この商品は有効期限切れです',
    'product.coming_soon': 'まもなく販売開始',
    'product.add_to_cart': 'カートに追加',
    'product.ok': '了解',
} as Record<string, string>;