export default {
    'redeemCoupon.title': 'クーポン交換',
    'redeemCoupon.message.one': '減算されます',
    'redeemCoupon.message.two': 'ポイントを使用して交換されます',
    'redeemCoupon.unit': '枚',
    'redeemCoupon.exchange_success': '交換成功',
    'redeemCoupon.exchange_fail': '交換失敗',
    'redeemCoupon.no_service.one': '現在クーポン交換サービスは提供されていません',
    'redeemCoupon.no_service.two': '必要な場合はブランドカスタマーサービスにお問い合わせください',
    'redeemCoupon.button': '交換',
    'redeemCoupon.ok_text.one': 'はい',
    'redeemCoupon.ok_text.two': '了解',
    'redeemCoupon.getting_time': 'クーポン受け取り時間',
    'redeemCoupon.time.start': '開始',
    'redeemCoupon.time.end': '終了',
} as Record<string, string>;

