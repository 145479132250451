export default {
    'storeOrders.title': '店舗注文',
    'storeOrders.last_three_months': '過去3か月',
    'storeOrders.last_six_months': '過去6か月',
    'storeOrders.last_one_year': '過去1年',
    'storeOrders.filter_state_category.one': 'カート/決済',
    'storeOrders.filter_state_category.two': '決済済み/支払い済み',
    'storeOrders.filter_state_category.three': 'キャンセル済み/返金済み',
    'storeOrders.filter_state_category.four': '一部キャンセル/一部返金',
    'storeOrders.order.no': '注文番号',
    'storeOrders.order.amount': '合計金額',
    'storeOrders.order.status': '注文状況',
    'storeOrders.order.payment_status': '支払い状況',
    'storeOrders.order.date': '注文日',
    'storeOrders.order.cancel_date': '注文キャンセル日',
    'storeOrders.order.detail': '注文詳細',
    'storeOrders.search_order.placeholder': '注文番号を入力してください',
    'storeOrders.empty': 'データなし',
    'storeOrders.carrier.phone': '携帯電話バーコード',
    'storeOrders.carrier.member': '会員キャリア',
    'storeOrders.carrier.company': '法人番号',
    'storeOrders.order_info': '注文情報',
    'storeOrders.order_refund_date': '注文返金日',
    'storeOrders.refund_amount': '返金額',
    'storeOrders.credit_card_last_four_digits': 'クレジットカード末尾4桁',
    'storeOrders.contact_number': '連絡先番号',
    'storeOrders.email': 'メールアドレス',
    'storeOrders.company_header': '会社ヘッダー',
    'storeOrders.order_detail': '商品の明細',
    'storeOrders.order_code': '注文バーコード',
    'storeOrders.close': '閉じる',
    'storeOrders.search_fail': '検索失敗',
    'storeOrders.text.research': '下のボタンをクリックして再検索を試みてください',
    'storeOrders.retry': '再試行',
} as Record<string, string>;