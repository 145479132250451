export default {
  'pointCard.title': 'スタンプカード',
  'pointCard.welcome': '各種イベントに参加してスタンプカードを獲得しましょう',
  'pointCard.my_card': 'マイスタンプカード',
  'pointCard.all_events': 'すべてのイベント',
  'pointCard.event_period': 'イベント期間',
  'pointCard.exchange_period': '交換期間',
  'pointCard.specified_store': '対象店舗',
  'pointCard.join': '参加',
  'pointCard.event_end': 'このイベントは終了しました',
  'pointCard.add_success': 'スタンプカードをマイカードに追加しました',
  'pointCard.event_deadline': 'イベント締切',
  'pointCard.detail': '詳細',
} as Record<string, string>;