export default {
    'collectPoint.title': '集點',
    'collectPoint.success': '集點成功',
    'collectPoint.obtained': '已獲得',
    'collectPoint.point': '點',
    'collectPoint.error.consumption_not_match': '此次消費尚未符合活動集點卡集點資格，故集點失敗',
    'collectPoint.error.collect_fail': '集點失敗',
    'collectPoint.enter_amount': '請輸入交易金額',
    'collectPoint.enter_point': '請輸入點數數量',
    'collectPoint.next_step': '下一步',
} as Record<string, string>;
