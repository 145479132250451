export default {
    'shareCoupon.title': 'チケット譲渡',
    'shareCoupon.success': '譲渡成功',
    'shareCoupon.alt_text': 'あなたへのギフトが届きました！早速確認しましょう～',
    'shareCoupon.unit': '枚',
    'shareCoupon.button': 'ギフトを開く',
    'shareCoupon.notice_message': '友達が24時間以内に受け取るのをお待ちください。受け取り完了後、譲渡が成功します。期限内に受け取られなかった場合、券は会員に戻されます。',
    'shareCoupon.share_fail_message': '譲渡失敗、LINEバージョンが古いため、LINEを更新してください',
    'shareCoupon.wrong_quantity': '譲渡数量が間違っています',
    'shareCoupon.wrong_product': 'この商品券は譲渡できません',
    'shareCoupon.fail': '譲渡失敗',
    'shareCoupon.please_enter_quantity': '譲渡数量を入力してください',
    'shareCoupon.you_have': '現在お持ちの数量は',
    'shareCoupon.next_step': '次へ',
} as Record<string, string>;