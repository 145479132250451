export default {
  'memberPoint.title': '',
  'memberPoint.detail': '',
  'memberPoint.store': '',
  'memberPoint.point': '',
  'memberPoint.current_point': '',
  'memberPoint.issue_date': '',
  'memberPoint.case': '',
  'memberPoint.receiver': '',
  'memberPoint.transfer': '',
} as Record<string, string>;
