export default {
  'memberCard.title': '',
  'memberCard.exchange': '',
  'memberCard.transfer': '',
  'memberCard.stamp': '',
  'memberCard.message.invalid': '',
  'memberCard.setting_invoice_carrier': '',
  'memberCard.show_invoice_carrier': '',
  'memberCard.hide_invoice_carrier': '',
  'memberCard.total_consumption': '',
  'memberCard.near_expired_point': '',
  'memberCard.point': '',
  'memberCard.expire': '',
  'memberCard.member_period': '',
  'memberCard.no_limit': '',
  'memberCard.to': '',
  'memberCard.need_consumption': '',
  'memberCard.point_rights': '',
  'memberCard.point_records': '',
  'memberCard.choose_notice': '',
  'memberCard.add_consumption_record': '',
  'memberCard.point.collect': '',
  'memberCard.add_trade_record': '',
  'memberCard.invalid': '',
  'memberCard.not_open': '',
  'memberCard.transfer_point': '',
  'memberCard.transfer_to_friend': '',
  'memberCard.transfer_success': '',
  'memberCard.error.can_not_transfer_to_self': '',
  'memberCard.error.friend_not_member': '',
  'memberCard.notice.enter_friend_phone': '',
  'memberCard.notice.friend_log_in': '',
  'memberCard.notice.will_share': '',
  'memberCard.notice.point_to': '',
  'memberCard.handle_time': '',
  'memberCard.error.member_point.not_match': '',
  'memberCard.change_send_point': '',
  'memberCard.change_deduct_point': '',
  'memberCard.current_accumulated_point': '',
  'memberCard.accumulate': '',
  'memberCard.accumulated_point': '',
  'memberCard.accumulated_member_point': '',
  'memberCard.point_change_fail': '',
  'memberCard.trade_has_accumulated': '',
  'memberCard.consumption_record_add_fail': '',
  'memberCard.no_member_benefit': '',
  'memberCard.your_member_level': '',
  'memberCard.other_member_level': '',
  'memberCard.total_accumulated_consumption': '',
  'memberCard.membership': '',
  'memberCard.inner_accumulated_consumption': '',
} as Record<string, string>;
