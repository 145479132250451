export default {
    'storeOrders.title': '商店訂單',
    'storeOrders.last_three_months': '最近三個月',
    'storeOrders.last_six_months': '最近六個月',
    'storeOrders.last_one_year': '最近一年',
    'storeOrders.filter_state_category.one': '購物車/結帳',
    'storeOrders.filter_state_category.two': '已結帳/付款',
    'storeOrders.filter_state_category.three': '已取消/退款',
    'storeOrders.filter_state_category.four': '已部分取消/退款',
    'storeOrders.order.no': '訂單編號',
    'storeOrders.order.amount': '總金額',
    'storeOrders.order.status': '訂單狀態',
    'storeOrders.order.payment_status': '支付狀態',
    'storeOrders.order.date': '訂單日期',
    'storeOrders.order.cancel_date': '取消訂單日期',
    'storeOrders.order.detail': '訂單詳情',
    'storeOrders.search_order.placeholder': '請輸入您的訂單編號',
    'storeOrders.empty': '無資料',
    'storeOrders.carrier.phone': '手機條碼',
    'storeOrders.carrier.member': '會員載具',
    'storeOrders.carrier.company': '統一編號',
    'storeOrders.order_info': '訂單資訊',
    'storeOrders.order_refund_date': '訂單退款日期',
    'storeOrders.refund_amount': '退款金額',
    'storeOrders.credit_card_last_four_digits': '信用卡末四碼',
    'storeOrders.contact_number': '信用卡末四碼',
    'storeOrders.email': '電子信箱',
    'storeOrders.company_header': '公司提頭',
    'storeOrders.order_detail': '商品明細',
    'storeOrders.order_code': '訂單條碼',
    'storeOrders.close': '關閉',
    'storeOrders.search_fail': '查詢失敗',
    'storeOrders.text.research': '您可以點擊下方按鈕試著重新查詢',
    'storeOrders.retry': '重試',
} as Record<string, string>;
