export default {
    'product.error.over_maximum_quantity': '超過單筆購買數量上限',
    'product.error.sold_out': '此商品已銷售完畢',
    'product.error.add_cart_fail': '加入購物車失敗',
    'product.available_store': '適用門市',
    'product.all': '全部',
    'product.introduction': '商品介紹',
    'product.discount_period': '優惠期間',
    'product.sale_period': '銷售期間',
    'product.every_set': '每組',
    'product.piece': '張',
    'product.remain_count': '剩餘數量',
    'product.notice': '注意事項',
    'product.publisher.title': '發行商資訊',
    'product.publisher.name': '睿鼎數位股份有限公司',
    'product.publisher.address': '地址:台北市大安區仁愛路四段25號3樓',
    'product.publisher.manager': '負責人:陳衍彰',
    'product.contract.title': '履約保證',
    'product.contract.content.one': '本券銷售所收取之面額，已存入發行人於永豐商業銀行開立之價金保管專戶，專款專用，所稱專用，係指供發行人履行交付商品或提供服務義務使用。',
    'product.contract.content.two': '保管期間自電子券銷售日(以票券上記載之銷售日期為準)起算一年。',
    'product.return_guide': '退貨退款說明',
    'product.shopping_guide': '購物須知',
    'product.expired': '此商品已逾期',
    'product.coming_soon': '即將開賣',
    'product.add_to_cart': '加入購物車',
    'product.ok': '好',
} as Record<string, string>;
