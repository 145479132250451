export default {
    'pointHistory.title': '',
    'pointHistory.event.not_match': '',
    'pointHistory.event.collect': '',
    'pointHistory.event.exchange': '',
    'pointHistory.event.send': '',
    'pointHistory.event.deduct': '',
    'pointHistory.event.pos_send': '',
    'pointHistory.event.pos_deduct': '',
    'pointHistory.event.transfer': '',
    'pointHistory.event.receive': '',
    'pointHistory.message.cancel_success': '',
    'pointHistory.message.cancel_fail': '',
    'pointHistory.current_points': '',
    'pointHistory.expiration_date': '',
    'pointHistory.button.cancel': '',
    'pointHistory.empty': '',
    'pointHistory.point_detail': '',
    'pointHistory.processing_time': '',
    'pointHistory.remark': '',
    'pointHistory.consumption_amount': '',
} as Record<string, string>;
