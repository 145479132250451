export default {
  'batchDeduct.batchCode.title': 'QRCode',
  'batchDeduct.batchCode.warning':
    'For personal use only. Reproduction or screenshots are invalid.',
  'batchDeduct.batchCode.code': 'Reimbursement Details',
  'batchDeduct.batchCode.counts': 'Coupons',
  'batchDeduct.batchCode.copySuccess': 'Copy verification code successfully',
  'batchDeduct.batchCode.success': 'Verification successful',
  'batchDeduct.batchCode.produce_fail': '',
  'batchDeduct.title': '',
  'batchDeduct.error.stamp_no_permission': '',
  'batchDeduct.cancel_all': '',
  'batchDeduct.partial_deduct': '',
  'batchDeduct.message.following_ticket_deduct_success': '',
} as Record<string, string>;
