export default {
  'pointCard.title': '集點卡',
  'pointCard.welcome': '歡迎參加各活動獲得集點卡',
  'pointCard.my_card': '我的集點卡',
  'pointCard.all_events': '所有活動',
  'pointCard.event_period': '活動期間',
  'pointCard.exchange_period': '兌換期間',
  'pointCard.specified_store': '限定門店',
  'pointCard.join': '加入',
  'pointCard.event_end': '此活動已結束',
  'pointCard.add_success': '成功加入我的集點卡',
  'pointCard.event_deadline': '活動期限',
  'pointCard.detail': '詳情',
} as Record<string, string>;
