export default {
    'shareCoupon.title': '轉贈票券',
    'shareCoupon.success': '轉贈成功',
    'shareCoupon.alt_text': '您的禮物來囉！趕快看看吧～',
    'shareCoupon.unit': '張',
    'shareCoupon.button': '打開我的禮物',
    'shareCoupon.notice_message': '等待好友於24小時之內領取，領取後即表示轉贈成功；逾期未領取該券會返回於會員。',
    'shareCoupon.share_fail_message': '轉贈失敗，Line 版本過舊，請更新 Line',
    'shareCoupon.wrong_quantity': '轉贈數量錯誤',
    'shareCoupon.wrong_product': '該商品券無法轉贈',
    'shareCoupon.fail': '轉贈失敗',
    'shareCoupon.please_enter_quantity': '請輸入轉贈數量',
    'shareCoupon.you_have': '您目前有',
    'shareCoupon.next_step': '下一步',
} as Record<string, string>;
