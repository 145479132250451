export default {
  'onlineStore.store': '',
  'onlineStore.coming_soon': '',
  'onlineStore.piece': '',
  'onlineStore.original_price': '',
  'onlineStore.text.building': '',
  'onlineStore.text.more_information': '',
  'onlineStore.category': '',
  'onlineStore.brand': '',
  'onlineStore.recommend': '',
  'onlineStore.package': '',
  'onlineStore.my_product': '',
  'onlineStore.12cm.company_name': '',
} as Record<string, string>;
