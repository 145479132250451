import type { AxiosError } from 'axios';

import type { Response } from './index';
import { echossAxios } from './index';

interface CouponsRes<T> {
  data: T;
}
interface CouponRes {
  data: CouponData;
}
export interface CouponData {
  array_couponinfo_id: (string | number)[];
  description: string;
  coupon_id: number;
  id: number;
  image: string | null;
  name: string;
  start: string;
  end: string;
  user_id: number;
  type: number;
  coupon_infos_id: number;
  url: string | null;
  total: number;
  transferable: number;
  useable: number;
  merchant_code: string;
  type_coupon_info_uuid: 1 | 2 | 3 | 4;
  array_coupon_infos_uuid: string[];
  // 1: 開放非會員領券 0:未開放非會員領券
  share_type: 0 | 1;
  redeemable: 0 | 1;
  shops: { id: number; name: string }[];
  redeem_shops: { id: number; name: string }[];
}
export interface ProductCouponData {
  id: number;
  product_id: number;
  // 商品名稱
  product_name: string;
  // 商品銷售期間類型 1 無期限 2指定期限
  period_sales: 1 | 2;
  // 商品使用期 起始日
  start_date: string | null;
  // 商品使用期 結束日
  end_date: string | null;
  // 商品圖片
  images: { id: number; url: string }[];
  // 持有張數
  count: number;
  // 持有商品券編號
  array_order_detail_id: number[];
  // 商品條碼
  array_order_detail_uuid: number[];
  // 商品介紹
  description: string;
  // 注意事項
  note: string;
  // 適用門市
  shops: { id: number; name: string }[];
  // 適用門市群組
  shop_groups: { id: number; name: string }[];
  useable: number;
  merchant_code: string;
  type_deduct: 1 | 2 | 3;
  // 是否可轉贈
  transferable: 0 | 1;
  redeemable: 0 | 1;
}
export interface CouponUsed {
  id: number;
  name: string;
  send_time: string;
  sender: string;
  used: number;
  shop_name: string;
  // 兌換時間
  deduct_time: string;
  // 核銷編號
  deduct_no_8?: string;
  // 條碼
  coupon_info_uuid?: string;
  // 批次核銷編號
  redeem_batch_uuid?: string;
}
export interface ProductCouponUsed {
  // 商品名稱
  products_name: string;
  // 核銷店家
  deduct_store: string;
  // 兌換時間
  deduct_time: string;
  // 核銷編號 - 簡碼
  deduct_no_8?: string;
  // 核銷編號
  deduct_no_36?: string;
  // 發放者
  brand_name: string;
  // 獲得時間
  order_time: string;
  // 訂單條碼
  order_detail_uuid: string;
  // 是否顯示核銷編碼 1是 0否
  show_deduct_no: 1 | 0;
  // 核銷狀態 2已核銷 4已作廢
  order_detail_status_lastest: 2 | 4;
  // 批次核銷編號
  redeem_batch_uuid?: string;
  // 備註
  note: '禮物' | '購買';
  // 訂單編號
  order_uuid: string;
  // 轉贈者手機號碼
  doner_phone?: string;
}
export interface CouponExpired {
  id: number;
  name: string;
  send_time: string;
  sender: string;
  expired_day: string;
  due_start: string;
  due_end: string;
  event: number;
  note?: string;
}

export interface ProductCouponExpired {
  products_name: string;
  brand_name: string;
  order_detail_uuid: string;
  id: number;
  checkout_at: string;
  end_date: string;
  status: string;
  // 備註
  note: '禮物' | '購買';
  // 訂單編號
  order_uuid: string;
  // 轉贈者手機號碼
  doner_phone?: string;
}

export interface CouponShared {
  id: number;
  kind: string;
  transfer_id?: number;
  name: string;
  type: number | null;
  typeName: '優惠券';
  quantity: number;
  created_at?: string;
  doner_phone: string;
  doner_line_uid: string;
  doner_time_get: string;
  cancelable: number;
  donee_phone: string;
  donee_name: string;
  donee_line_uid: string;
  donee_time_get: string;
  cancelled_at: string;
  status: number;
  status_name: string;
  send_time: string;
  overdue_time: string;
}
export interface ProductCouponShared extends Omit<CouponShared, 'typeName'> {
  type_name: '商品券' | '餘額券';
  // 備註
  note: '禮物' | '購買';
  // 訂單編號
  order_uuid: string;
  voucher_no: string;
}
/**
 * 獲取票券資訊
 * @param token token
 */
export const getCoupon = async (
  token: string,
  params?: { redeemable?: 1 | 0 },
): Promise<Response<CouponData[]>> => {
  try {
    const { data } = await echossAxios.request<CouponsRes<CouponData[]>>({
      url: '/api/member/coupon',
      method: 'GET',
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return { data: data.data };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};

/**
 * 獲取商品券資訊
 * @param token token
 * @apinums 580
 */
export const getProductCoupons = async (
  token: string,
  params?: { redeemable?: 1 | 0 },
): Promise<Response<ProductCouponData[]>> => {
  try {
    const { data } = await echossAxios.request<CouponsRes<ProductCouponData[]>>({
      url: '/api/member/commodity',
      method: 'GET',
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return { data: data.data };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};
/**
 * 獲取單一票券資訊
 * @param id coupon id
 * @param token token
 */
export const getCouponById = async (id: string, token: string): Promise<Response<CouponData>> => {
  try {
    const { data } = await echossAxios.request<CouponRes>({
      url: `/api/member/coupon-info/${id}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return { data: data.data };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};
/**
 * 獲取單一票券資訊 (無Token)
 * @param id coupon id
 * @apinums 536
 */
export const getCouponWithoutToken = async (
  id: string,
  params: { brand_id: number },
): Promise<Response<CouponData>> => {
  try {
    const { data } = await echossAxios.request<CouponRes>({
      url: `/api/member/coupon/${id}`,
      method: 'GET',
      params,
    });

    return { data: data.data };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};
/**
 * 獲取商品券資訊
 * @param token token
 */
export const getProductCouponById = async (
  id: string,
  token: string,
): Promise<Response<ProductCouponData>> => {
  try {
    const { data } = await echossAxios.request<CouponsRes<ProductCouponData>>({
      url: `/api/member/commodity-detail/${id}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return { data: data.data };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};
/**
 * 獲取已使用票券資訊
 * @param token token
 * @apinums 532
 */
export const getCouponUsed = async (token: string): Promise<Response<CouponUsed[]>> => {
  try {
    const { data } = await echossAxios.request<CouponsRes<CouponUsed[]>>({
      url: '/api/member/coupon-used',
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return { data: data.data };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};
/**
 * 獲取已使用商品券資訊
 * @param token token
 * @apinums 582
 */
export const getProductCouponUsed = async (
  token: string,
): Promise<Response<ProductCouponUsed[]>> => {
  try {
    const { data } = await echossAxios.request<CouponsRes<ProductCouponUsed[]>>({
      url: '/api/member/commodity-used',
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return { data: data.data };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};
/**
 * 獲取已失效票券資訊
 * @param token token
 */
export const getCouponExpired = async (token: string): Promise<Response<CouponExpired[]>> => {
  try {
    const { data } = await echossAxios.request<CouponsRes<CouponExpired[]>>({
      url: '/api/member/coupon-expired',
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return { data: data.data };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};
/**
 * 獲取已使用商品券資訊
 * @param token token
 */
export const getProductCouponExpired = async (
  token: string,
): Promise<Response<ProductCouponExpired[]>> => {
  try {
    const { data } = await echossAxios.request<CouponsRes<ProductCouponExpired[]>>({
      url: '/api/member/commodity-expired',
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return { data: data.data };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};
/**
 * 獲取已轉贈票券資訊
 * @param token token
 * @apinums 552
 */
export const getCouponShared = async (token: string): Promise<Response<CouponShared[]>> => {
  try {
    const { data } = await echossAxios.request<CouponsRes<CouponShared[]>>({
      url: '/api/member/transfer',
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return { data: data.data };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};

/**
 * 獲取已轉贈商品券資訊
 * @param token token
 * @apinums 611
 */
export const getProductCouponShared = async (
  token: string,
  params?: {
    voucher_type: 'CASH' | 'NORMAL';
  },
): Promise<Response<ProductCouponShared[]>> => {
  try {
    const { data } = await echossAxios.request<CouponsRes<ProductCouponShared[]>>({
      url: '/api/member/commodity-transfer-log',
      method: 'GET',
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return { data: data.data };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};

export interface ReceivedCoupon {
  kind: string;
  transfer_id: number;
  name: string;
  type: number;
  type_name: string;
  quantity: string;
  doner_time_get: string | null;
  doner_phone: string;
  doner_name: string;
  doner_line_uid: string;
  send_time: string;
  overdue_time: string;
  cancelled_at: string | null;
  cancelable: number;
  donee_phone: string;
  donee_name: string;
  donee_line_uid: string;
  donee_time_get: string | null;
  status: number;
  status_name: string;
  order_detail_id1: number;
  order_uuid: string;
  voucher_nos: string[];
}

/**
 * 獲取收禮記錄
 * @param token token
 * @apinums 614
 */
export const getCouponReceived = async (
  token: string,
  params?: {
    type: 'coupon' | 'CASH' | 'NORMAL';
  },
): Promise<Response<ReceivedCoupon[]>> => {
  try {
    const { data } = await echossAxios.request<CouponsRes<ReceivedCoupon[]>>({
      url: '/api/member/get-donee-trans-log',
      method: 'GET',
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return { data: data.data };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};
/**
 * 取消轉贈票券
 * @param couponID coupon ID
 * @param token
 * @apinums 553
 * @returns
 */
export const cancelShared = async (
  couponID: number,
  token: string,
  cancelType?: 2,
): Promise<Response<{ message: string }>> => {
  try {
    const { data } = await echossAxios.request<CouponsRes<{ message: string }>>({
      url: `/api/member/transfer/${couponID}`,
      method: 'DELETE',
      params: {
        cancel_type: cancelType,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return { data: data.data };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};
/**
 * 取消轉贈商品券
 * @param couponID coupon ID
 * @param token
 * @apinums 553
 * @returns
 */
export const cancelSharedProductCoupon = async (
  couponID: number,
  token: string,
  cancelType?: 1 | 2,
): Promise<Response<{ message: string }>> => {
  try {
    const { data } = await echossAxios.request<CouponsRes<{ message: string }>>({
      url: `/api/member/commodity-transfer-cancel/${couponID}`,
      method: 'GET',
      params: {
        cancel_type: cancelType,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return { data: data.data };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};
/**
 * 印章核銷票券
 * @param postData stamp token and coupon id
 * @param token token
 * @apinums 534
 */
export const stampExchange = async (
  postData: { token: string; coupon_info_id: number[] },
  token: string,
): Promise<
  Response<{
    data: {
      message: string;
      show_deduct_no: 1 | 0;
      arary_deduct_info: { deduct_no_28: string; deduct_no_18: string; deduct_no_8: string }[];
    };
  }>
> => {
  try {
    const { data } = await echossAxios.request<{
      data: {
        message: string;
        show_deduct_no: 1 | 0;
        arary_deduct_info: { deduct_no_28: string; deduct_no_18: string; deduct_no_8: string }[];
      };
    }>({
      url: '/api/member/coupon-stamp',
      method: 'POST',
      data: postData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return { data };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};

/**
 * 印章核銷商品券
 * @param postData stamp token and product coupon id
 * @param token token
 * @apinums API 583 商品券核銷 by stamp
 * @returns
 */
export const stampExchangeProductCoupon = async (
  postData: { token: string; order_detail_id: number[] },
  token: string,
): Promise<
  Response<{
    data: {
      message: string;
      show_deduct_no: 1 | 0;
      info_deduct_no: { deduct_no_36: string; deduct_no_18: string; deduct_no_8: string }[];
    };
  }>
> => {
  try {
    const { data } = await echossAxios.request<{
      data: {
        message: string;
        show_deduct_no: 1 | 0;
        info_deduct_no: { deduct_no_36: string; deduct_no_18: string; deduct_no_8: string }[];
      };
    }>({
      url: '/api/member/commodity-stamp',
      method: 'POST',
      data: postData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return { data };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};

/**
 * 短網址領券
 * @param postData.coupon_id coupon id
 * @param token token
 * @apinums 535
 * @returns
 */
export const collectCoupon = async (
  postData: { coupon_id: string },
  token: string,
): Promise<Response<{ data: { message: string; coupon_info_id: number } }>> => {
  try {
    const { data } = await echossAxios.request<{
      data: { message: string; coupon_info_id: number };
    }>({
      url: '/api/member/coupon-url',
      method: 'POST',
      data: postData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return { data };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};

interface ShareCouponResp {
  coupon_name: string;
  coupon_image: string;
  transfer_code: string;
  transfer_id: number;
  message?: string;
}
interface ShareCouponResponse<T> {
  error?: AxiosError<{ data: { message: string; errors: { [key: string]: string } } }>;
  data?: T;
}
/**
 * 轉贈票券
 * @param postData.coupon_info_ids coupon ID
 * @param postData.quantity 數量
 * @param token token
 * @apinums 550
 * @returns
 */
export const shareCoupon = async (
  postData: { coupon_info_ids: (string | number)[]; quantity: number },
  token: string,
): Promise<ShareCouponResponse<ShareCouponResp>> => {
  try {
    const { data } = await echossAxios.request<{ data: ShareCouponResp }>({
      url: '/api/member/transfer',
      method: 'POST',
      data: postData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return { data: data.data };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};
interface ShareProductCouponResp {
  product_name: string;
  product_image_first: string;
  transfer_code: string;
  transfer_id: number;
  message?: string;
}
/**
 * 轉贈商品券
 * @param postData.order_detail_ids product coupon IDs
 * @param postData.quantity 數量
 * @param token token
 * @apinums 610
 * @returns
 */
export const shareProductCoupon = async (
  postData: { order_detail_ids: (string | number)[]; quantity: number },
  token: string,
): Promise<ShareCouponResponse<ShareProductCouponResp>> => {
  try {
    const { data } = await echossAxios.request<{ data: ShareProductCouponResp }>({
      url: '/api/member/commodity-transfer',
      method: 'POST',
      data: postData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return { data: data.data };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};
/**
 * 領取轉贈票券
 * @param code 領券代碼
 * @param token token
 * @apinums 551
 * @returns
 */
export const collectCouponByCode = async (
  code: string,
  token: string,
): Promise<Response<{ message: string }>> => {
  try {
    const { data } = await echossAxios.request<{ data: { message: string } }>({
      url: `/api/member/tf/${code}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return { data: data.data };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};

/**
 * 領取轉贈商品券
 * @param code 領券代碼
 * @param token token
 * @apinums 613
 * @returns
 */
export const collectProductCouponByCode = async (
  code: string,
  token: string,
): Promise<Response<{ message: string }>> => {
  try {
    const { data } = await echossAxios.request<{ data: { message: string } }>({
      url: `/api/member/commodity-transfer-get/${code}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return { data: data.data };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};

export interface BatchList {
  redeem_type: 1 | 2;
  redeem_id: number;
  redeem_quantity: number;
}

/**
 * 產生批次核銷碼
 * @param postData.batch_list 欲核銷之優惠券/商品券
 * @param token token
 * @apinums 921
 * @returns
 */
export const batchDeductCoupons = async (
  postData: {
    batch_list: BatchList[];
  },
  token: string,
): Promise<Response<{ success: boolean; message: string; batch_token: string }>> => {
  try {
    const { data } = await echossAxios.request<{
      data: { success: boolean; message: string; batch_token: string };
    }>({
      url: '/api/member/create-redeem-batch',
      method: 'POST',
      data: postData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return { data: data.data };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};

/**
 * 更換核銷條碼
 * @param postData.batch_token 欲更換之核銷條碼
 * @param token token
 * @apinums 92˙
 * @returns
 */
export const refreshBatchToken = async (
  postData: {
    batch_token: string;
  },
  token: string,
): Promise<Response<{ success: boolean; message: string; batch_token: string }>> => {
  try {
    const { data } = await echossAxios.request<{
      data: { success: boolean; message: string; batch_token: string };
    }>({
      url: '/api/member/change-redeem-batch',
      method: 'POST',
      data: postData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return { data: data.data };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};

/**
 * 批次核碼
 * @param postData.batch_uuid 欲核銷之批次核銷碼
 * @param token token
 * @apinums 925
 * @returns
 */
export const batchDeduct = async (
  postData: {
    batch_token: string;
    stamp_token: string;
    preview_mode?: 1;
  },
  token: string,
): Promise<
  Response<{
    success: boolean;
    message: string;
    stamped_store_name?: string;
    success_data?: string[];
    failure_data?: string[];
  }>
> => {
  try {
    const { data } = await echossAxios.request<{
      data: {
        success: boolean;
        message: string;
        stamped_store_name?: string;
        success_data?: string[];
        failure_data?: string[];
      };
    }>({
      url: '/api/member/execute-batch-redeem',
      method: 'POST',
      data: postData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return { data: data.data };
  } catch (err) {
    const error = err as AxiosError<{
      data: {
        success: boolean;
        message: string;
        stamped_store_name?: string;
        success_data?: string[];
        failure_data?: string[];
      };
    }>;

    return { error, data: error.response?.data.data };
  }
};

/**
 * 批次核銷 優惠券列表
 * @param token token
 * @apinums 530-merge
 * @returns
 */
export const batchCouponList = async (token: string): Promise<Response<CouponData[]>> => {
  try {
    const { data } = await echossAxios.request<CouponsRes<CouponData[]>>({
      url: '/api/member/coupon-merge?redeemable=1',
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return { data: data.data };
  } catch (error: any) {
    console.log(error);
    const err = error as AxiosError;
    if (err.response?.data?.success_data) {
      return { error, data: err.response?.data };
    }
    return { error, data: undefined };
  }
};

/**
 * 批次核銷 商品券列表
 * @param token token
 * @apinums 580-merge
 * @returns
 */
export const batchProductCouponList = async (
  token: string,
): Promise<Response<ProductCouponData[]>> => {
  try {
    const { data } = await echossAxios.request<CouponsRes<ProductCouponData[]>>({
      url: '/api/member/commodity-merge?redeemable=1',
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return { data: data.data };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};

export interface BalanceCoupon {
  order_details_id: number;
  product_id: number;
  voucher_hash_id: string;
  product_name: string;
  images: Image[];
  start_date: string | null;
  end_date: string | null;
  phone_number: string;
  redeem_term_id: null;
  merchant_code: string;
  redeem_start_date: null;
  redeem_end_date: null;
  redeemable: number;
  voucher_no: string;
  remaining: number;
  order_detail_id: number;
  is_using: 0 | 1;
}

export interface Image {
  id: number;
  url: string;
  order: null;
}

/**
 * 餘額券列表
 * @param token token
 * @apinums 660
 * @returns
 */
export const getBalanceCouponList = async (
  token: string,
  params?: { is_using?: 0 | 1 },
): Promise<Response<BalanceCoupon[]>> => {
  try {
    const { data } = await echossAxios.request<CouponsRes<BalanceCoupon[]>>({
      url: '/api/member/cash-voucher-list',
      method: 'GET',
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return { data: data.data };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};

export interface SingleBalanceCoupon {
  success: boolean;
  message: string;
  data: SingleBalanceCouponItem;
}

export interface SingleBalanceCouponItem {
  product_name: string;
  product_id: number;
  period_sales: number;
  sales_start_date: null;
  sales_end_date: null;
  images: Image[];
  description: string;
  note: string;
  shops: any[];
  useable: number;
  merchant_code: string;
  type_deduct: number;
  shop_groups: any[];
  start_date: string;
  end_date: string;
  transferable: boolean;
  redeem_term_id: null;
  phone_number: string;
  period_redeem: number;
  redeem_start_date: null;
  redeem_end_date: null;
  remaining: number;
  voucher_no: string;
  voucher_log: VoucherLog[];
  order_detail_id: number;
}

export interface VoucherLog {
  log_id: string;
  order_detail_id: number;
  cash: number;
  handle_store: string;
  created_at: string;
}

/**
 * 單一餘額券資訊與使用紀錄
 * @param token token
 * @apinums 661
 * @returns
 */
export const getBalanceCouponById = async (
  voucherNo: string,
  token: string,
): Promise<Response<SingleBalanceCouponItem>> => {
  try {
    const { data } = await echossAxios.request<SingleBalanceCoupon>({
      url: `/api/member/cash-voucher-detail/${voucherNo}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return { data: data.data };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};

export interface RedeemBalanceCouponResp {
  success: boolean;
  message: string;
  error_code?: string;
  data: any[];
}

/**
 * 餘額券核銷
 * @param token token
 * @apinums 662
 * @returns
 */
export const redeemBalanceCoupon = async (
  postData: {
    voucher_no: string;
    cash: number;
    token: string;
  },
  token: string,
): Promise<Response<RedeemBalanceCouponResp>> => {
  try {
    const { data } = await echossAxios.request<RedeemBalanceCouponResp>({
      url: '/api/member/cash-voucher-redeem',
      method: 'POST',
      data: postData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return { data };
  } catch (error: any) {
    console.log(error);
    const err = error as AxiosError;
    if (err.response?.data) {
      return { error, data: err.response.data };
    }
    return { error, data: undefined };
  }
};

export interface BalanceCouponHistoryItem {
  order_detail_id: number;
  voucher_no_12cm: string;
  order_uuid: string;
  product_name: string;
  note: string;
  get_voucher_time: string;
  latest_used_time: string;
  latest_store: string;
  doner_phone?: string;
  expired_time?: string;
  used_amount?: number;
  refund?: number;
}

/**
 * 獲取已使用餘額券券資訊
 * @param token token
 * @apinums 663
 */
export const getBalanceCouponUsed = async (
  token: string,
): Promise<Response<BalanceCouponHistoryItem[]>> => {
  try {
    const { data } = await echossAxios.request<CouponsRes<BalanceCouponHistoryItem[]>>({
      url: '/api/member/cash-voucher-list-used',
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return { data: data.data };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};
/**
 * 獲取已失效餘額券券資訊
 * @param token token
 * @apinums 664
 */
export const getBalanceCouponExpired = async (
  token: string,
): Promise<Response<BalanceCouponHistoryItem[]>> => {
  try {
    const { data } = await echossAxios.request<CouponsRes<BalanceCouponHistoryItem[]>>({
      url: '/api/member/cash-voucher-list-expired',
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return { data: data.data };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};

export const queryRedeemBatch = async (access_token: string, batch_token: string) => {
  try {
    const formData = new FormData();
    formData.append('batch_token', batch_token);
    const { data } = await echossAxios.request({
      url: 'api/member/query-redeem-batch',
      method: 'POST',
      data: formData,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });

    return data;
  } catch (e: any) {
    if (!!e.response) {
      return e.response.data;
    } else {
      console.error(e);
      return { success: false, message: 'Error' };
    }
  }
};
