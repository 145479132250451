export default {
    'transactions.title': '取引履歴',
    'transactions.empty': 'データなし',
    'transactions.order.no': '注文番号',
    'transactions.order.status': '注文状況',
    'transactions.order.purchase_date': '購入日',
    'transactions.order.item': '購入商品',
    'transactions.order.amount': '支払金額',
    'transactions.order.payment': '支払方法',
    'transactions.order.credit_card': 'クレジットカード',
    'transactions.last_month': '最近1ヶ月',
    'transactions.purchase_complete': '購入完了',
    'transactions.view_tickets': 'チケットを見る',
    'transactions.trade_record': '取引記録',
} as Record<string, string>;
