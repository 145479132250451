import type { AxiosError } from 'axios';
import axios from 'axios';

export interface Response<T> {
  error?: AxiosError<{
    data: { message?: string; data?: { message: string }; errors?: { [key: string]: string } } & T;
  }>;
  data?: T;
  message?: string;
  meta?: {
    current_page: number;
    per_page: number;
    total: number;
  };
}

export const echossAxios = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? '/' : process.env.REACT_APP_DOMAIN,
});
echossAxios.interceptors.response.use(
  (response) => response,
  (error) => {
    // console.log(error.response.status);
    const errorJson = error.toJSON();
    const apiUrl = errorJson.config.url.slice(
      0,
      errorJson.config.url.indexOf('?') !== -1
        ? errorJson.config.url.indexOf('?')
        : errorJson.config.url.length,
    );
    if (
      error.request.responseURL.indexOf('/api/member/brands/') === -1 &&
      error.response.status === 503
    ) {
      const parsedData = window.location.pathname.split('/');
      const brandIdentify = parsedData[1];
      window.location.href = `/${brandIdentify}`;
    }
    if (error.request.responseURL.indexOf('/api/member/login') === -1) {
      if (
        error.request.responseURL.indexOf('api/member/commodity-transfer-get') !== -1 ||
        error.request.responseURL.indexOf('api/member/transfer') !== -1 ||
        error.request.responseURL.indexOf('api/member/coupon-url') !== -1 ||
        error.request.responseURL.indexOf('api/member/tf') !== -1
      ) {
        const errorMessage = error.response.data.data.message;
        if (
          errorMessage === 'transfer over' ||
          errorMessage === 'over coupon event allow limit' ||
          errorMessage === 'coupon already in ticket folder' ||
          errorMessage === 'commodity already in ticket folder' ||
          errorMessage === 'user had got this coupon from url before' ||
          errorMessage === 'commodity transfer over 24hrs' ||
          errorMessage === 'transfer cancelled' ||
          errorMessage === 'this coupon during the inactive period' ||
          errorMessage === 'coupon has expired' ||
          errorMessage === 'commodity has expired' ||
          errorMessage === 'count for coupon is over the limit.' ||
          errorMessage === 'coupon info id error'
        ) {
          throw error;
        }
      }
    }
    throw error;
  },
);
interface LoginType {
  data: {
    access_token: string;
    expire_in: number;
    token_type: string;
    // 一般用戶 1 正式用戶 0
    is_common: 0 | 1;
  };
}
/**
 * 會員登入
 * @param lineToken line ID Token
 * @param brandID 品牌ID
 */
export const Login = async (
  lineToken: string,
  brandID: number,
  autoRegister?: boolean,
  phone_number?: string,
): Promise<Response<LoginType>> => {
  try {
    const { data } = await echossAxios.request<LoginType>({
      url: '/api/member/login',
      method: 'POST',
      data: {
        brand_id: brandID,
        by_line_id: autoRegister,
      },
      headers: {
        Authorization: `Bearer ${lineToken}`,
      },
    });
    return { data };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};

/**
 * GodMode會員登入
 * @param brand_id 品牌ID
 * @param gm_token gm_token
 * @param param phone number
 */
export const GodModeLogin = async (
  brandID: number,
  gm_token: string,
  param: string,
): Promise<Response<LoginType>> => {
  try {
    const { data } = await echossAxios.request<LoginType>({
      url: '/api/member/login',
      method: 'POST',
      data: {
        brand_id: brandID,
        gm_token: gm_token,
        param: param,
      },
      // headers: {
      //   Authorization: `Bearer ${lineToken}`,
      // },
    });
    return { data };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};

export interface BrandInfo {
  id: number;
  liff_id: string;
  name: string;
  user_agreement: string;
  privacy_policy: string;
  brand_code: string;
  identifier: string;
  member_benifit: string;
  language: string;
  required_registration_toggles: {
    user_email: number;
    user_gender: number;
    user_city: number;
    user_job: number;
    user_edu: number;
    user_mrg: number;
    user_hbt: number;
    user_diet: number;
    user_birth: number;
    user_name: number;
    user_invoice_carrier: number;
  };
  brand_modules: {
    // 集點卡
    loyalty_card: 1 | 0;
    // 會員卡
    member_card: 1 | 0;
    // 入會禮
    welcome_gift: 1 | 0;
    // 生日禮
    birth_gift: 1 | 0;
    // 優惠券
    coupon: 1 | 0;
    // 商店
    store: 1 | 0;
    // 外部活動
    exteranl_activity: 1 | 0;
    // 核銷編號
    show_deduct_no: 1 | 0;
    // 票券核銷方式 1印章 2條碼 3並存
    type_deduct: 1 | 2 | 3;
    // 點數使用方式 1印章 2條碼
    type_points: 1 | 2;
    otp: 1 | 0;
  };
  menu: { id: string; title: string }[];
  menu_color: string;
  line_oa?: string;
  // 會員卡是否啟用
  member_card_available: 0 | 1;
  // 會員集點卡是否啟用
  mps_card_available: 0 | 1;
  // 會員卡新增消費金額是否啟用
  add_amount_available: 0 | 1;
  // 會員集點卡集點是否啟用
  add_mps_available: 0 | 1;
  // 會員集點卡扣點是否啟用
  deduct_mps_available: 0 | 1;
  // 會員集點卡轉贈點數是否啟用
  transfer_mps_available: 0 | 1;
  // 自動轉券/自由選券是否啟用
  mps_to_coupon_available: 0 | 1;
  // 是否界接肚肚POS
  with_dudoo: 0 | 1;
}
interface BrandInfoRes {
  data: BrandInfo;
}
/**
 * 獲取品牌相關設定
 * - liff id
 * - menu order
 * - main olor
 * @param brandID 品牌ID
 */
export const getBrandInfo = async (brandID: string): Promise<Response<BrandInfo>> => {
  try {
    const { data } = await echossAxios.request<BrandInfoRes>({
      url: `/api/member/brands/${brandID}`,
    });
    return { data: data.data };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};

interface MemberRegister {
  data: {
    message: string;
  };
}
type RegisterFormData = {
  user_email?: string | null;
  user_phone?: string | null;
  user_phone_confirmation?: string | null;
  user_name?: string | null;
  user_city?: string | null;
  user_gender?: string | null;
  user_job?: string | null;
  user_edu?: string | null;
  user_mrg?: string | null;
  user_hbt?: string | null;
  user_diet?: string | null;
  user_birth?: string | null;
  user_invoice_carrier?: string | null;
  answers?: { id: string; item_id?: string; answer?: string | null }[];
};
interface RegisterResponse<T> {
  error?: AxiosError<{ message: string; errors: { [key: string]: string } }>;
  data?: T;
}
/**
 * 會員註冊
 * @param lineToken line token
 * @param brandID 品牌 ID
 * @param formData 註冊資料
 */
export const postRegister = async (
  lineToken: string,
  brandID: number,
  formData: RegisterFormData,
): Promise<RegisterResponse<MemberRegister>> => {
  try {
    const { data } = await echossAxios.request<MemberRegister>({
      url: '/api/member/register',
      method: 'POST',
      data: {
        ...formData,
        brand_id: `${brandID}`,
      },
      headers: {
        Authorization: `Bearer ${lineToken}`,
      },
    });

    return { data: data };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};
export type TagFormData = {
  tag: string;
  line_id: string;
  brand_id: string;
};
/**
 * 貼標籤 API
 * @param formData TagFormData
 * @returns
 */
export const tagUser = async (
  formData: TagFormData,
): Promise<Response<{ data: { message: string } }>> => {
  try {
    const { data } = await echossAxios.request<{ data: { message: string } }>({
      url: '/api/tag-user-by-liff',
      method: 'POST',
      data: {
        ...formData,
      },
    });

    return { data: data };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};

/**
 * OTP Send
 * @param formData OTPFormData
 * @returns
 * @api https://www.notion.so/OTP-API-ad345e67bb62497886ac5d9ca4215aaa
 */
export type OTPFormData = {
  // R: 註冊 M: 修改
  type: 'R' | 'M';
  user_phone: string;
  brand_id: number;
};
export const requestOtp = async (
  formData: OTPFormData,
): Promise<Response<{ data: { message: string } }>> => {
  try {
    const { data } = await echossAxios.request<{ data: { message: string } }>({
      url: '/api/member/otp/send-auth-code',
      method: 'POST',
      data: {
        ...formData,
      },
    });

    return { data: data };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};

/**
 * Manual OTP Validation
 * @param formData ManualOTPFormData
 * @returns
 * @api 508
 */
export type ManualOTPFormData = {
  user_phone: string;
  auth_code: string;
  brand_id: string;
};
export const manualOtpValidate = async (
  formData: ManualOTPFormData,
): Promise<Response<{ data: { message: string } }>> => {
  try {
    const { data } = await echossAxios.request<{ data: { message: string } }>({
      url: '/api/member/date-otp/authentication',
      method: 'POST',
      data: {
        ...formData,
      },
    });

    return { data: data };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};

/**
 * 獲取擴充欄位
 * @returns
 * @apinums 505
 */
export type ExtendField = {
  id: number;
  required: 0 | 1;
  name: string;
  items?: { id: number; name: string }[];
  // 1: 選項題 2:問答題
  type: 1 | 2;
  // 2: 選擇日期 3: 輸入整數值 4: 輸入字串
  answer_type: 1 | 2 | 3 | 4;
};
export const queryExtendFields = async (
  brand_id: number,
): Promise<Response<{ data: ExtendField[] }>> => {
  try {
    const { data } = await echossAxios.request<{ data: ExtendField[] }>({
      url: '/api/member/user-extends',
      params: {
        brand_id,
      },
      method: 'GET',
    });

    return { data: data };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};

export interface ExistField {
  id: number;
  name: string;
  email: null;
  email_verified_at: null;
  created_at: string;
  updated_at: string;
  line_id: null;
  brand_id: number;
  phone_number: string;
  gender: string;
  occupation: string;
  location: string;
  education_level: string;
  marriage_status: string;
  interest: string;
  dietary_preference: string;
  birthday: string;
  invoice_carrier: null;
  member_card_id: null;
  get_mc_reason: null;
  get_mc_source: null;
  total_amount: null;
  period_amount: null;
  gift_time: null;
  common: number;
  disconnect_line: number;
  uid: string;
  source_tag_id: number;
  member_card_updated_at: string;
  should_member_card_be_renewed: boolean;
  should_member_card_renew_period_be_used: boolean;
  mps_card_points: number;
  pre_line_id: string;
  age: number;
  age_name: string;
  join_date: string;
  member_card_due_at: null;
  rich_menu_updated_at: null;
  rich_menu_alias_id: null;
  freeze_coupon_id: null;
}
/**
 * OTP驗證獲取匯入的會員資料
 * @returns
 * @apinums 507
 */
export const queryExistFieldsWithDateOTPAuth = async (params: {
  auth_code: string;
  brand_id: string;
  user_phone?: string;
  line_id?: string;
}): Promise<Response<ExistField>> => {
  try {
    const { data } = await echossAxios.request<{ data: { message: string; user: ExistField } }>({
      url: '/api/member/date-otp/authentication-user-fields',
      data: {
        ...params,
      },
      method: 'POST',
    });

    return { data: data.data.user, message: data.data.message };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};
/**
 * OTP驗證獲取匯入的會員資料
 * @returns
 * @apinums 507
 */
export const queryExistFieldsWithOTPAuth = async (params: {
  auth_code: string;
  brand_id: string;
  user_phone?: string;
  line_id?: string;
}): Promise<Response<ExistField>> => {
  try {
    const { data } = await echossAxios.request<{ data: { message: string; user: ExistField } }>({
      url: '/api/member/otp/authentication-user-fields',
      data: {
        ...params,
      },
      method: 'POST',
    });

    return { data: data.data.user, message: data.data.message };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};

export interface Widget {
  id: number;
  brand_id: number;
  name: string;
  icon: string;
  background_color: string;
  target_url: string;
}

export const queryWidgets = async (token: string): Promise<Response<Widget[]>> => {
  try {
    const { data } = await echossAxios.request<{ data: Widget[] }>({
      url: '/api/member/widgets',
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return { data: data.data };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};

export const queryCountries = async () => {
  return echossAxios.request<{ data: Record<string, string> }>({
    url: '/api/country-list',
    method: 'GET',
  });
};
