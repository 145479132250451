import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Dialog from 'components/Dialog';
import LoadingIndicator from 'components/LoadingIndicator';
import LoginFailed from 'components/LoginFailed';
import { useAuth } from 'context/Auth';
import { useBrand } from 'context/Brand';
import useCollectCoupon from 'hooks/useCollectCoupon';
import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useRoutes, useSearchParams } from 'react-router-dom';
import { errorMessageMapping, successMessageMapping } from 'utils';

import { getShortUri } from '../api/shortUri';
import { ErrorBoundary } from '../components/ErrorBoundary';
import routes from './normalRoutes';

const queryClient = new QueryClient();

const Routes: FC = () => {
  const { error: authError, logging, user } = useAuth();
  const [collectDialog, setCollectDialog] = useState<{
    visible: boolean;
    success: boolean;
    redirect?: string;
    msg: string;
  }>({
    visible: false,
    success: false,
    msg: '',
  });
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const { brandInfo } = useBrand();

  const [searchParams] = useSearchParams();
  const collectID = useMemo(() => searchParams.get('collect'), [searchParams]);
  const productID = useMemo(() => searchParams.get('product'), [searchParams]);
  const redirectUri = useMemo(() => searchParams.get('r'), [searchParams]);

  const shortUriRedirect = useCallback(async () => {
    if (!!redirectUri && !!user?.accessToken) {
      window.location.href = await getShortUri(user.accessToken, redirectUri);
    }
  }, [redirectUri, user?.accessToken]);

  useEffect(() => {
    shortUriRedirect();
  }, [redirectUri, shortUriRedirect, user]);

  useEffect(() => {
    if (!pathname.startsWith('/benefit') && !pathname.startsWith('/register')) {
      if (!!collectID && !pathname.startsWith('/coupon')) {
        navigate(`/coupon?collect=${collectID}`);
      }

      if (!!productID) {
        navigate(`/product/${productID}`);
      }
    }
  }, [collectID, navigate, pathname, productID]);

  const element = useRoutes(
    routes(
      {
        menuID: brandInfo?.menu[0].id,
        isMember: user?.isMember || user?.isNormal,
        isNormal: user?.isNormal,
      },
      search,
    ),
  );

  const onSuccess = useCallback((message: string, redirect: string) => {
    const msg = successMessageMapping(message);
    setCollectDialog({ visible: true, success: true, msg, redirect });
  }, []);

  const onError = useCallback((message?: string) => {
    const msg = errorMessageMapping(message || '');
    setCollectDialog({ visible: true, success: false, msg });
  }, []);

  useCollectCoupon({
    onSuccess,
    onError,
    disable: !user?.isLoggedIn || logging || !!authError,
    collectByUrl: !collectID,
  });

  const onConfirm = () => {
    if (collectDialog?.success) {
      navigate(collectDialog.redirect || '/coupon', { replace: true });
      setCollectDialog({ ...collectDialog, visible: false });
    } else {
      navigate(`${pathname}`, { replace: true });
      setCollectDialog({ ...collectDialog, visible: false });
    }
  };

  const onCollectConfirm = () => {
    navigate(`/coupon/byurl?collect=${collectID}`, { replace: true });
  };

  const onCollectCancel = () => {
    navigate(`${pathname}`, { replace: true });
  };

  if (logging || !!redirectUri) {
    return LoadingIndicator;
  }

  if (authError) {
    return <LoginFailed />;
  }

  if (!user) {
    return null;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <ErrorBoundary>
        {element}
        <Dialog
          visible={
            !!collectID &&
            !!user.id &&
            !pathname.includes('benefit') &&
            !pathname.includes('register')
          }
          cancelable={true}
          onConfirm={onCollectConfirm}
          onCancel={onCollectCancel}
          message={'確定領取優惠券？'}
          okText={'確定'}
          cancelText={'取消'}
        />
        <Dialog visible={collectDialog.visible} onConfirm={onConfirm} message={collectDialog.msg} />
      </ErrorBoundary>
    </QueryClientProvider>
  );
};

export default Routes;
