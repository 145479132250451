export default {
    'collectPoint.title': '',
    'collectPoint.success': '',
    'collectPoint.obtained': '',
    'collectPoint.point': '',
    'collectPoint.error.consumption_not_match': '',
    'collectPoint.error.collect_fail': '',
    'collectPoint.enter_amount': '',
    'collectPoint.enter_point': '',
    'collectPoint.next_step': '',
} as Record<string, string>;
