export default {
  'components.DatePicker.clear': '',
  'components.DatePicker.cancel': '',
  'components.DatePicker.choose': '',
  'components.DatePicker.confirm': '',
  'components.Dialog.no': '',
  'components.LoginFailed.title': '',
  'components.LoginFailed.notice': '',
  'components.Maintenance.title': '',
  'components.Maintenance.notice': '',
  'components.Maintenance.refresh': '',
  'components.MenuTabBar.ticket': '',
  'components.MenuTabBar.my': '',
  'components.MenuTabBar.menu_item.more': '',
  'components.MenuTabBar.menu_item.home': '',
  'components.MenuTabBar.menu_item.member_card': '',
  'components.MenuTabBar.menu_item.coupon': '',
  'components.MenuTabBar.menu_item.store': '',
  'components.MenuTabBar.menu_item.loyalty_card': '',
  'components.MenuTabBar.menu_item.member_area': '',
  'components.NotFound.title': '',
  'components.NotFound.notice': '',
  'components.OpenInLINE.browser_invalid': '',
  'components.OpenInLINE.guide.title': '',
  'components.OpenInLINE.guide.step.one': '',
  'components.OpenInLINE.guide.step.two': '',
  'components.OpenInLINE.guide.step.three': '',
  'components.OpenInLINE.guide.finish': '',
  'components.OTPPhone.ManualOTPDialog.enter_verification_code': '',
  'components.OTPPhone.verification_fail': '',
  'components.OTPPhone.resend_seconds': '',
  'components.OTPPhone.send_verification_code': '',
  'components.OTPPhone.auth_code.required': '',
  'components.OTPPhone.message_unreceived': '',
  'components.OTPPhone.message_auth_code': '',
  'components.OTPPhone.verified': '',
} as Record<string, string>;
