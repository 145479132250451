export default {
  'coupon.title': '票券',
  'coupon.content': '票券內容',
  'coupon.record': '票券記錄',
  'coupon.discount': '優惠券',
  'coupon.product': '商品券',
  'coupon.balance': '餘額券',
  'coupon.select_redeem_number': '選取核銷張數',
  'coupon.error.redeem_fail': '核銷失敗',
  'coupon.error.redeem_fail.not_shop': '核銷失敗，非單一門市限定的印章',
  'coupon.error.redeem_fail.not_shop_group': '核銷失敗，非門市群組限定的印章',
  'coupon.error.redeem_fail.shop_not_found': '核銷失敗，未獲取門市資訊',
  'coupon.error.redeem_fail.store_not_found': '核銷失敗，查無此分店/門市資訊',
  'coupon.error.redeem_fail.coupon_invalid': '核銷失敗，票券不可使用',
  'coupon.error.redeem_fail.remaining_not_enough': '該票券可使用餘額不足',
  'coupon.applicable_store': '適用門市',
  'coupon.use_period': '使用期限',
  'coupon.quantity_held': '持有張數',
  'coupon.transfer': '轉贈',
  'coupon.not_enabled': '此票券尚未啟用',
  'coupon.period_is': '此票券使用期間為',
  'coupon.barcode': '條碼',
  'coupon.guide': '說明',
  'coupon.choose_redeem_quantity': '請選擇核銷張數',
  'coupon.empty.product': '目前商品券夾是空的',
  'coupon.empty.ticket': '目前票券夾是空的',
  'coupon.empty.balance': '目前餘額券夾是空的',
  'coupon.welcome': '歡迎參加各活動獲取票券',
  'coupon.redeemable': '可兌換',
  'coupon.not_start': '未啟用',
  'coupon.redeem_immediately': '立即兌換',
  'coupon.notification_time': '通知時間',
  'coupon.receive_time': '領取時間',
  'coupon.giver': '贈禮者',
  'coupon.copy_success': '已複製條碼成功',
  'coupon.redeem_coupon': '核銷票券',
  'coupon.redeem_store': '核銷門市',
  'coupon.not_start_yet': '此優惠券尚未啟用',
  'coupon.redeem_count': '核銷張數',
  'coupon.redeemed': '已核銷',
  'coupon.expired': '已失效',
  'coupon.voided': '已作廢',
  'coupon.receive': '收禮',
  'coupon.balance_amount': '餘額',
  'coupon.using': '使用中',
  'coupon.not_used': '未使用',
  'coupon.use_record': '使用記錄',
  'coupon.use_amount': '使用金額',
  'coupon.use_time': '使用時間',
  'coupon.use_store': '使用門市',
  'coupon.ticket_serial_number': '票券序號',
  'coupon.remaining_amount': '剩餘額度',
  'coupon.enter_use_amount': '請輸入使用金額',
  'coupon.cancel_transfer_success': '取消轉贈成功',
  'coupon.cancel_transfer_fail': '取消轉贈失敗',
  'coupon.obtain_time': '獲得時間',
  'coupon.gift': '禮物',
  'coupon.issuer': '發放者',
  'coupon.redeem_time': '兌換時間',
  'coupon.redeem_status': '核銷狀態',
  'coupon.redeem_no': '核銷單號',
  'coupon.disable_time': '失效時間',
  'coupon.account_status': '帳務狀態',
  'coupon.overdue_time': '逾期時間',
  'coupon.cancel_transfer': '取消轉贈',
  'coupon.count_number': '張數',
  'coupon.used_amount': '已使用金額',
  'coupon.recent_use_time': '最近使用時間',
  'coupon.recent_use_store': '最近使用門市',
} as Record<string, string>;
