export default {
  'register.alert.confirm': '利用規約に同意してください',
  'register.alert.register_fail': '登録に失敗しました。もう一度お試しください。',
  'register.alert.phone_number.exists':
    'この電話番号は既に使用されています。他の番号を入力してください。',
  'register.alert.phone_number.verify_fail': '電話番号の認証に失敗しました。再入力してください。',
  'register.alert.email.exists':
    'このメールアドレスは既に使用されています。他のメールアドレスを入力してください。',
  'register.alert.email.verify_fail': 'メールアドレスの認証に失敗しました。再入力してください。',
  'register.alert.birthday.verify_fail': '生年月日の認証に失敗しました。再入力してください。',
  'register.alert.gender.verify_fail': '性別の認証に失敗しました。再入力してください。',
  'register.alert.city.verify_fail': '居住地の認証に失敗しました。再入力してください。',
  'register.alert.job.verify_fail': '職業の認証に失敗しました。再入力してください。',
  'register.alert.education_level.verify_fail': '学歴の認証に失敗しました。再入力してください。',
  'register.alert.marriage.verify_fail': '婚姻状況の認証に失敗しました。再入力してください。',
  'register.alert.interest.verify_fail': '趣味の認証に失敗しました。再入力してください。',
  'register.alert.diet.verify_fail': '食事の嗜好の認証に失敗しました。再入力してください。',
  'register.alert.line_account.registered': 'このLINEアカウントは既に登録されています。',
  'register.alert.otp.verify_fail': 'OTP認証に失敗しました。再試行してください。',
  'register.alert.field_required': '未入力の項目があります。もう一度お試しください。',
  'register.alert.please_select': '選択してください',
  'register.alert.number_required': '数字を入力してください',
  'register.alert.number_length': '最大18桁まで入力可能です。再入力してください。',
  'register.phone_number': '電話番号',
  'register.phone_number.required': '電話番号を入力してください',
  'register.phone_number.format_error': '電話番号の形式が正しくありません',
  'register.phone_number.not_match': '電話番号が一致しません',
  'register.phone_number.enter_again': '再度電話番号を入力してください',
  'register.name': '名前',
  'register.name.required': '名前を入力してください',
  'register.name.format_error': '英字または漢字のみ入力可能です',
  'register.birth': '生年月日',
  'register.birth.required': '生年月日を入力してください',
  'register.email': 'Eメール',
  'register.email.required': 'Eメールを入力してください',
  'register.email.format_error': 'Eメールの形式が正しくありません',
  'register.gender': '性別',
  'register.gender.required': '性別を選択してください',
  'register.city': '居住地',
  'register.city.required': '居住地を選択してください',
  'register.job': '職業',
  'register.job.required': '職業を選択してください',
  'register.eduction_level': '学歴',
  'register.eduction_level.required': '学歴を選択してください',
  'register.marriage': '婚姻状況',
  'register.marriage.required': '婚姻状況を選択してください',
  'register.habit': '趣味',
  'register.habit.required': '趣味を選択してください',
  'register.diet': '食事の嗜好',
  'register.diet.required': '食事の嗜好を選択してください',
  'register.agree': '私は同意します',
  'register.term': '利用規約',
  'register.account_verification': 'アカウント認証',
  'register.member_information': '会員情報',
  'register.complete': '登録完了',
  'register.submit': '送信',
  'register.success': '登録成功',
  'register.country_code': '国コード',
  'register.country_code.required': '国コードを選択してください',
} as Record<string, string>;
