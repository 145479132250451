export default {
  'memberPoint.title': '會員點數',
  'memberPoint.detail': '點數明細',
  'memberPoint.store': '門市',
  'memberPoint.point': '點數',
  'memberPoint.current_point': '目前累積點數',
  'memberPoint.issue_date': '發點日期',
  'memberPoint.case': '事件',
  'memberPoint.receiver': '收禮者',
  'memberPoint.transfer': '轉贈者',
} as Record<string, string>;
