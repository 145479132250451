export default {
  'components.DatePicker.clear': '清除',
  'components.DatePicker.cancel': '取消',
  'components.DatePicker.choose': '請選擇日期',
  'components.DatePicker.confirm': '確認',
  'components.Dialog.no': '否',
  'components.LoginFailed.title': '登入失敗',
  'components.LoginFailed.notice': '請關閉並重新開啟此頁面',
  'components.Maintenance.title': '系統維護中',
  'components.Maintenance.notice': '您可以稍後再試著重新整理頁面',
  'components.Maintenance.refresh': '重新整理',
  'components.MenuTabBar.ticket': '票券',
  'components.MenuTabBar.my': '我的',
  'components.MenuTabBar.menu_item.more': '更多',
  'components.MenuTabBar.menu_item.home': '首頁',
  'components.MenuTabBar.menu_item.member_card': '會員卡',
  'components.MenuTabBar.menu_item.coupon': '票券',
  'components.MenuTabBar.menu_item.store': '商店',
  'components.MenuTabBar.menu_item.loyalty_card': '集點卡',
  'components.MenuTabBar.menu_item.member_area': '會員專區',
  'components.NotFound.title': '找不到此商家資料',
  'components.NotFound.notice': '您可以點擊下方按鈕試著重新尋找',
  'components.OpenInLINE.browser_invalid': '無法透過此瀏覽器使用 Echoss VIP',
  'components.OpenInLINE.guide.title': '請依下方指示設定：',
  'components.OpenInLINE.guide.step.one': '1. 開啟 LINE App中的「設定」',
  'components.OpenInLINE.guide.step.two': '2. 進入「LINE Labs」',
  'components.OpenInLINE.guide.step.three': '3. 關閉「使用預設瀏覽器開啟連結」項目',
  'components.OpenInLINE.guide.finish': '完成後，再次點擊開啟連結即可使用',
  'components.OTPPhone.ManualOTPDialog.enter_verification_code': '請輸入店家提供之四位數字驗證碼',
  'components.OTPPhone.verification_fail': '驗證失敗',
  'components.OTPPhone.resend_seconds': '秒後重新發送',
  'components.OTPPhone.send_verification_code': '發送認證碼',
  'components.OTPPhone.auth_code.required': '請輸入驗證碼',
  'components.OTPPhone.message_unreceived': '沒收到簡訊？',
  'components.OTPPhone.message_auth_code': '簡訊認證碼',
  'components.OTPPhone.verified': '已驗證',
} as Record<string, string>;
