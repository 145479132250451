export default {
  'onlineStore.store': 'ショップ',
  'onlineStore.coming_soon': '近日発売',
  'onlineStore.piece': '枚',
  'onlineStore.original_price': '元の価格',
  'onlineStore.text.building': 'コンテンツ構築中',
  'onlineStore.text.more_information': '詳細は公式サイト/ソーシャルメディアをご覧ください',
  'onlineStore.category': 'カテゴリ',
  'onlineStore.brand': 'ブランド',
  'onlineStore.recommend': 'おすすめ',
  'onlineStore.package': 'パッケージ券',
  'onlineStore.my_product': '私の商品券',
  'onlineStore.12cm.company_name': '睿鼎デジタル株式会社',
} as Record<string, string>;
