export default {
    'shareCoupon.title': '',
    'shareCoupon.success': '',
    'shareCoupon.alt_text': '',
    'shareCoupon.unit': '',
    'shareCoupon.button': '',
    'shareCoupon.notice_message': '',
    'shareCoupon.share_fail_message': '',
    'shareCoupon.wrong_quantity': '',
    'shareCoupon.wrong_product': '',
    'shareCoupon.fail': '',
    'shareCoupon.please_enter_quantity': '',
    'shareCoupon.you_have': '',
    'shareCoupon.next_step': '',
} as Record<string, string>;
