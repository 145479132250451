export default {
    'pointHistory.title': 'ポイント履歴',
    'pointHistory.event.not_match': 'ポイント付与対象外',
    'pointHistory.event.collect': 'ポイント収集',
    'pointHistory.event.exchange': '交換',
    'pointHistory.event.send': 'ポイント付与',
    'pointHistory.event.deduct': 'ポイント減算',
    'pointHistory.event.pos_send': 'POSポイント付与',
    'pointHistory.event.pos_deduct': 'POSポイント減算',
    'pointHistory.event.transfer': '譲渡',
    'pointHistory.event.receive': '受領',
    'pointHistory.message.cancel_success': 'ポイント取消成功',
    'pointHistory.message.cancel_fail': 'ポイント取消失敗',
    'pointHistory.current_points': '現在のポイント',
    'pointHistory.expiration_date': '有効期限',
    'pointHistory.button.cancel': 'キャンセル',
    'pointHistory.empty': 'データなし',
    'pointHistory.point_detail': 'ポイント詳細',
    'pointHistory.processing_time': '処理時間',
    'pointHistory.remark': '備考',
    'pointHistory.consumption_amount': '消費金額',
} as Record<string, string>;
