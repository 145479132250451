export default {
    'home.title': '首頁',
    'home.event': '活動',
    'home.news': '公告',
    'home.event_information': '活動訊息',
    'home.announcement': '公告訊息',
    'home.goto': '前往',
    'home.latest_events': '最新活動',
    'home.more_events': '更多活動',
} as Record<string, string>;
