import lang_en_us from 'locales/en-US/_entry';
import lang_ja from 'locales/ja/_entry';
import lang_zh_tw from 'locales/zh-TW/_entry';

// 如果有新的語言包，在 import 之後需要在這邊新增進候選區域中。
const languages: Record<string, Record<string, string>> = {
  'zh-TW': lang_zh_tw,
  'en-US': lang_en_us,
  ja: lang_ja,
};

// 使用方式：
// 一般的文本：
//   FormattedMessage('tmp');
//   {tmp: '測試文本'}

// 需要替換部分值(例如資料庫回傳)：
//   FormattedMessage('tmp', {n: 1, m: 2, c: 'test'});
//   {tmp: '最小值：{n}，最大值：{m}，補充訊息：{c}'}
const FormattedMessage = (id: string, value?: Record<string, string | number>) => {
  const langPack = languages[localStorage.getItem('lang') as string] || lang_zh_tw;
  let returnedString = langPack[id];

  if (!!value) {
    const replaceable = Object.keys(value);

    for (let i = 0; i < replaceable.length; i++) {
      const key = replaceable[i];
      const val = value[key] as string;

      returnedString = returnedString.replaceAll(`{${replaceable[i]}}`, val);
    }
  }

  return returnedString;
};

export default FormattedMessage;
