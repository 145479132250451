export default {
    'collectPoint.title': 'ポイント集め',
    'collectPoint.success': 'ポイント取得成功',
    'collectPoint.obtained': '獲得済み',
    'collectPoint.point': 'ポイント',
    'collectPoint.error.consumption_not_match': '今回の利用はキャンペーンのポイントカード条件を満たしていないため、ポイント取得に失敗しました',
    'collectPoint.error.collect_fail': 'ポイント取得に失敗しました',
    'collectPoint.enter_amount': '取引金額を入力してください',
    'collectPoint.enter_point': 'ポイント数を入力してください',
    'collectPoint.next_step': '次へ',
} as Record<string, string>;
