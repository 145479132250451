export default {
  'coupon.title': 'クーポン',
  'coupon.content': 'クーポン内容',
  'coupon.record': 'クーポン履歴',
  'coupon.discount': '割引',
  'coupon.product': '商品',
  'coupon.balance': '残高',
  'coupon.select_redeem_number': '利用枚数を選択',
  'coupon.error.redeem_fail': '利用に失敗しました',
  'coupon.error.redeem_fail.not_shop': '利用に失敗しました。特定店舗限定のスタンプではありません',
  'coupon.error.redeem_fail.not_shop_group':
    '利用に失敗しました。店舗グループ限定のスタンプではありません',
  'coupon.error.redeem_fail.shop_not_found': '利用に失敗しました。店舗情報が取得できません',
  'coupon.error.redeem_fail.store_not_found': '利用に失敗しました。該当店舗情報が見つかりません',
  'coupon.error.redeem_fail.coupon_invalid': '利用に失敗しました。このクーポンは使用できません',
  'coupon.error.redeem_fail.remaining_not_enough': 'このクーポンの利用可能残高が不足しています',
  'coupon.applicable_store': '適用店舗',
  'coupon.use_period': '利用期間',
  'coupon.quantity_held': '保有枚数',
  'coupon.transfer': 'ギフトとして送る',
  'coupon.not_enabled': 'このクーポンはまだ有効化されていません',
  'coupon.period_is': 'このクーポンの利用期間は',
  'coupon.barcode': 'バーコード',
  'coupon.guide': '説明',
  'coupon.choose_redeem_quantity': '利用枚数を選択してください',
  'coupon.empty.product': '現在、商品クーポンフォルダは空です',
  'coupon.empty.ticket': '現在、クーポンフォルダは空です',
  'coupon.empty.balance': '現在、残高クーポンフォルダは空です',
  'coupon.welcome': '各種イベントに参加してクーポンを獲得しましょう',
  'coupon.redeemable': '交換可能',
  'coupon.not_start': '未有効',
  'coupon.redeem_immediately': '今すぐ交換',
  'coupon.notification_time': '通知時間',
  'coupon.receive_time': '受け取り時間',
  'coupon.giver': 'ギフト送信者',
  'coupon.copy_success': 'バーコードをコピーしました',
  'coupon.redeem_coupon': 'クーポンを利用',
  'coupon.redeem_store': '利用店舗',
  'coupon.not_start_yet': 'このクーポンはまだ有効化されていません',
  'coupon.redeem_count': '利用枚数',
  'coupon.redeemed': '利用済み',
  'coupon.expired': '失効',
  'coupon.voided': '無効化済み',
  'coupon.receive': 'ギフトを受け取る',
  'coupon.balance_amount': '残高',
  'coupon.using': '利用中',
  'coupon.not_used': '未使用',
  'coupon.use_record': '利用履歴',
  'coupon.use_amount': '利用金額',
  'coupon.use_time': '利用時間',
  'coupon.use_store': '利用店舗',
  'coupon.ticket_serial_number': 'クーポン番号',
  'coupon.remaining_amount': '残額',
  'coupon.enter_use_amount': '利用金額を入力してください',
  'coupon.cancel_transfer_success': 'ギフト送信のキャンセルが成功しました',
  'coupon.cancel_transfer_fail': 'ギフト送信のキャンセルに失敗しました',
  'coupon.obtain_time': '獲得時間',
  'coupon.gift': 'ギフト',
  'coupon.issuer': '発行者',
  'coupon.redeem_time': '交換時間',
  'coupon.redeem_status': '利用状況',
  'coupon.redeem_no': '利用番号',
  'coupon.disable_time': '失効時間',
  'coupon.account_status': 'アカウント状況',
  'coupon.overdue_time': '期限切れ時間',
  'coupon.cancel_transfer': 'ギフト送信をキャンセル',
  'coupon.count_number': '枚数',
  'coupon.used_amount': '利用済み金額',
  'coupon.recent_use_time': '最近の利用時間',
  'coupon.recent_use_store': '最近の利用店舗',
} as Record<string, string>;
