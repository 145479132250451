import { echossAxios } from './index';

const loggingResponse = async (response: any) => {
  await echossAxios('/api/front_logging', {
    method: 'GET',
    params: response,
  });
};

export default class Logistics {
  static log(response: any) {
    try {
      loggingResponse({
        environment: 'frontend',
        log_level: 'info',
        log: JSON.stringify(response),
      });
    } catch (e) {
      console.error(e);
    }
  }

  static info(response: any) {
    try {
      loggingResponse({
        environment: 'frontend',
        log_level: 'info',
        log: JSON.stringify(response),
      });
    } catch (e) {
      console.error(e);
    }
  }

  static warn(response: any) {
    try {
      loggingResponse({
        environment: 'frontend',
        log_level: 'warning',
        log: JSON.stringify(response),
      });
    } catch (e) {
      console.error(e);
    }
  }

  static warning(response: any) {
    try {
      loggingResponse({
        environment: 'frontend',
        log_level: 'warning',
        log: JSON.stringify(response),
      });
    } catch (e) {
      console.error(e);
    }
  }

  static error(response: any) {
    try {
      loggingResponse({
        environment: 'frontend',
        log_level: 'error',
        log: JSON.stringify(response),
      });
    } catch (e) {
      console.error(e);
    }
  }
}
