export default {
  'memberCard.title': '会員カード',
  'memberCard.exchange': '交換',
  'memberCard.transfer': '譲渡',
  'memberCard.stamp': 'スタンプ',
  'memberCard.message.invalid': '会員カード機能はまだ利用できません。お楽しみに。',
  'memberCard.setting_invoice_carrier': '請求書キャリアを設定',
  'memberCard.show_invoice_carrier': '請求書キャリアを表示',
  'memberCard.hide_invoice_carrier': '請求書キャリアを隠す',
  'memberCard.total_consumption': '累計消費金額',
  'memberCard.near_expired_point': 'まもなく期限切れのポイント',
  'memberCard.point': 'ポイント',
  'memberCard.expire': '期限',
  'memberCard.member_period': '会員有効期間',
  'memberCard.no_limit': '無期限',
  'memberCard.to': 'まで',
  'memberCard.need_consumption': '追加消費',
  'memberCard.point_rights': 'ポイント特典',
  'memberCard.point_records': 'ポイント履歴',
  'memberCard.choose_notice': '使用方法を選択してください',
  'memberCard.add_consumption_record': '消費記録を追加',
  'memberCard.point.collect': 'ポイント収集',
  'memberCard.add_trade_record': '取引記録を追加',
  'memberCard.invalid': '未有効',
  'memberCard.not_open': '未開放',
  'memberCard.transfer_point': 'ポイントを譲渡',
  'memberCard.transfer_to_friend': '友達に譲渡',
  'memberCard.transfer_success': '譲渡成功！',
  'memberCard.error.can_not_transfer_to_self': '自分にポイントを譲渡することはできません',
  'memberCard.error.friend_not_member':
    '友達がプラットフォームの会員ではないため譲渡できません。友達に会員登録を促しましょう。',
  'memberCard.notice.enter_friend_phone': '友達の電話番号を入力してください',
  'memberCard.notice.friend_log_in': '友達がこのサービスにログインするとポイントを確認できます',
  'memberCard.notice.will_share': '譲渡するポイントは',
  'memberCard.notice.point_to': 'ポイントを次の方に贈ります：',
  'memberCard.handle_time': '処理時間',
  'memberCard.error.member_point.not_match':
    '今回の消費は会員ポイントカードの条件を満たしていないため、ポイント収集に失敗しました。この消費は累計消費金額に記録されました。',
  'memberCard.change_send_point': 'ポイント発行の変更',
  'memberCard.change_deduct_point': 'ポイント減算の変更',
  'memberCard.current_accumulated_point': '今回の累積ポイントは',
  'memberCard.accumulate': '消費',
  'memberCard.accumulated_point': '累積ポイント',
  'memberCard.accumulated_member_point': '会員累積ポイント',
  'memberCard.point_change_fail': 'ポイント変更に失敗しました',
  'memberCard.trade_has_accumulated': 'この取引は累計消費金額に記録されました',
  'memberCard.consumption_record_add_fail': '消費記録の追加に失敗しました',
  'memberCard.no_member_benefit': '現在、会員特典はありません',
  'memberCard.your_member_level': '現在の会員ランク',
  'memberCard.other_member_level': 'その他の会員ランク',
  'memberCard.total_accumulated_consumption': '総累計消費',
  'memberCard.membership': '会員資格',
  'memberCard.inner_accumulated_consumption': '内部累積消費',
} as Record<string, string>;
