import { ReactComponent as CardSVG } from 'assets/icon-card-filled.svg';
import { ReactComponent as GiftSVG } from 'assets/icon-gift-outline.svg';
import { ReactComponent as HomeSVG } from 'assets/icon-home-filled.svg';
import { ReactComponent as MoreSVG } from 'assets/icon-more-outline.svg';
import { ReactComponent as MySVG } from 'assets/icon-my-filled.svg';
import { ReactComponent as StampSVG } from 'assets/icon-stamp-filled.svg';
import { ReactComponent as TicketSVG } from 'assets/icon-ticket-filled.svg';
import MoreMenu from 'components/MoreMenu';
import { useBrand } from 'context/Brand';
import type { FC } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import FormattedMessage from '../FormattedMessage';
import styles from './index.module.scss';
export type MenuItem = {
  id?: string;
  icon?: (color: string) => JSX.Element | null;
  title?: string;
  url?: string;
  onClick?: () => void;
  exact?: boolean;
};

const preMenu: MenuItem[] = [
  {
    id: 'coupon',
    icon: (color = '#999') => <TicketSVG fill={color} />,
    title: FormattedMessage('components.MenuTabBar.ticket'),
    url: '/pre/coupon',
    exact: true,
  },
  {
    id: 'my',
    icon: (color = '#999') => <MySVG fill={color} />,
    title: FormattedMessage('components.MenuTabBar.my'),
    url: '/pre/me',
    exact: true,
  },
];
type MenuTabBarProps = {
  pre?: boolean;
};
const genMenuProps = (menuId: string): MenuItem => {
  switch (menuId) {
    case 'home':
      return {
        icon: (color = '#999') => <HomeSVG fill={color} />,
        url: '/home',
        exact: false,
      };
    case 'member_card':
      return {
        icon: (color = '#999') => <CardSVG fill={color} />,
        url: '/membercard',
        exact: true,
      };
    case 'loyalty_card':
      return {
        icon: (color = '#999') => <StampSVG fill={color} />,
        url: '/pointcards',
        exact: true,
      };
    case 'coupon':
      return {
        icon: (color = '#999') => <TicketSVG fill={color} />,
        url: '/coupon',
        exact: true,
      };
    case 'store':
      return {
        icon: (color = '#999') => <GiftSVG fill={color} />,
        url: '/store',
        exact: true,
      };
    case 'member_area':
      return {
        icon: (color = '#999') => <MySVG fill={color} />,
        url: '/memberarea',
        exact: true,
      };
    case 'more':
      return { icon: (color = '#999') => <MoreSVG fill={color} />, exact: true };
    default:
      return {
        title: '',
        exact: true,
        icon: () => null,
        onClick: () => {},
      };
  }
};

const MenuTabBar: FC<MenuTabBarProps> = (props) => {
  const { pre } = props;
  const { brandInfo } = useBrand();
  const [visible, setVisible] = useState<boolean>(false);

  const [menu, setMenu] = useState<MenuItem[]>([]);
  const [moreMenu, setMoreMenu] = useState<MenuItem[]>([]);
  useEffect(() => {
    if (brandInfo && brandInfo.menu) {
      let tmpMenu = brandInfo.menu
        .slice(0, 4)
        .map((brandMenu) => ({ ...brandMenu, ...genMenuProps(brandMenu.id) }));
      let moreMenItems: MenuItem[] = [];
      if (brandInfo.menu.length > 5) {
        tmpMenu.push({
          id: 'more',
          icon: (color = '#999') => <MoreSVG fill={color} />,
          title: FormattedMessage('components.MenuTabBar.menu_item.more'),
          onClick: () => {
            setVisible(true);
          },
          exact: true,
        });
        moreMenItems = brandInfo.menu
          .slice(4, brandInfo.menu.length)
          .map((brandMenu) => ({ ...brandMenu, ...genMenuProps(brandMenu.id) }));
      } else {
        tmpMenu = brandInfo.menu
          .slice(0, 5)
          .map((brandMenu) => ({ ...brandMenu, ...genMenuProps(brandMenu.id) }));
      }
      setMenu(tmpMenu);
      setMoreMenu(moreMenItems);
    }
  }, [brandInfo]);
  return (
    <>
      <div className={styles.menuTabBar}>
        {pre
          ? preMenu.map((preMenuItem, index) => (
              <NavLink
                to={preMenuItem.url || ''}
                className={({ isActive }) =>
                  `${styles.menuItem}${isActive ? ` ${styles.active}` : ''}`
                }
                key={preMenuItem.id}
              >
                <div>{preMenuItem.icon && preMenuItem.icon('#999')}</div>
                <span>{preMenuItem.title}</span>
              </NavLink>
            ))
          : menu.map((menuItem, index) =>
              menuItem.id === 'more' ? (
                <button onClick={menuItem.onClick} className={styles.menuItem} key={menuItem.id}>
                  <div>{menuItem.icon && menuItem.icon('#999')}</div>
                  <span>{menuItem.title}</span>
                </button>
              ) : (
                <NavLink
                  to={menuItem.url || ''}
                  className={({ isActive }) =>
                    `${styles.menuItem}${isActive ? ` ${styles.active}` : ''}`
                  }
                  key={menuItem.id}
                >
                  <div>{menuItem.icon && menuItem.icon('#999')}</div>
                  <span>
                    {FormattedMessage('components.MenuTabBar.menu_item.' + (menuItem.id ?? ''))}
                  </span>
                </NavLink>
              ),
            )}
      </div>
      <MoreMenu visible={visible} toggleVisible={setVisible} moreMenu={moreMenu} />
    </>
  );
};

export default MenuTabBar;
