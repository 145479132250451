import { ReactComponent as OpenLINE } from '../../assets/img_openLINE.svg';
import FormattedMessage from '../FormattedMessage';
import styles from './index.module.scss';
const OpenInLINE = () => {
  return (
    <div className={styles.openInLine}>
      <OpenLINE />
      <div className={styles.description}>
        <p>{FormattedMessage('components.OpenInLINE.browser_invalid')}</p>
        <div className={styles.instruction}>
          <p>{FormattedMessage('components.OpenInLINE.guide.title')}</p>
          <p>{FormattedMessage('components.OpenInLINE.guide.step.one')}</p>
          <p>{FormattedMessage('components.OpenInLINE.guide.step.two')}</p>
          <p>{FormattedMessage('components.OpenInLINE.guide.step.three')}</p>
          <p>{FormattedMessage('components.OpenInLINE.guide.finish')}</p>
        </div>
      </div>
    </div>
  );
};
export default OpenInLINE;
