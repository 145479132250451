export default {
  'memberCard.title': '會員卡',
  'memberCard.exchange': '兌換',
  'memberCard.transfer': '轉贈',
  'memberCard.stamp': '印章',
  'memberCard.message.invalid': '會員卡功能尚未開放使用，敬請期待。',
  'memberCard.setting_invoice_carrier': '設定發票載具',
  'memberCard.show_invoice_carrier': '顯示發票載具',
  'memberCard.hide_invoice_carrier': '收起發票載具',
  'memberCard.total_consumption': '總累計消費',
  'memberCard.near_expired_point': '即將到期點數',
  'memberCard.point': '點',
  'memberCard.expire': '到期',
  'memberCard.member_period': '會員效期',
  'memberCard.no_limit': '無期限',
  'memberCard.to': '距離',
  'memberCard.need_consumption': '再消費',
  'memberCard.point_rights': '集點權益',
  'memberCard.point_records': '點數記錄',
  'memberCard.choose_notice': '請選擇使用方式',
  'memberCard.add_consumption_record': '新增消費記錄',
  'memberCard.point.collect': '集點',
  'memberCard.add_trade_record': '新增交易記錄',
  'memberCard.invalid': '尚未啟用',
  'memberCard.not_open': '尚未開放',
  'memberCard.transfer_point': '轉贈點數',
  'memberCard.transfer_to_friend': '轉贈好友',
  'memberCard.transfer_success': '轉贈成功！',
  'memberCard.error.can_not_transfer_to_self': '無法轉贈點數給自己',
  'memberCard.error.friend_not_member': '好友非平台會員，故無法轉贈，趕緊通知好友註冊會員吧',
  'memberCard.notice.enter_friend_phone': '請輸入好友手機號碼',
  'memberCard.notice.friend_log_in': '您的好友登入此服務後即可查看點數',
  'memberCard.notice.will_share': '您要轉贈',
  'memberCard.notice.point_to': '點給',
  'memberCard.handle_time': '處理時間',
  'memberCard.error.member_point.not_match':
    '此次消費尚未符合會員集點卡集點資格，故集點失敗；此次消費已為您紀錄於累計消費金額。',
  'memberCard.change_send_point': '異動發點',
  'memberCard.change_deduct_point': '異動扣點',
  'memberCard.current_accumulated_point': '本次累積點數為',
  'memberCard.accumulate': '消費',
  'memberCard.accumulated_point': '累積點數',
  'memberCard.accumulated_member_point': '累積會員點數',
  'memberCard.point_change_fail': '點數異動失敗',
  'memberCard.trade_has_accumulated': '此次交易已為您紀錄於累計消費金額',
  'memberCard.consumption_record_add_fail': '消費記錄新增失敗',
  'memberCard.no_member_benefit': '暫無會員權益內容',
  'memberCard.your_member_level': '您目前的會員等級',
  'memberCard.other_member_level': '其他會員等級',
  'memberCard.total_accumulated_consumption': '總累積消費',
  'memberCard.membership': '會籍',
  'memberCard.inner_accumulated_consumption': '內累積消費',
} as Record<string, string>;
