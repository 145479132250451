export default {
  'memberAreaJourney.title': '會員歷程',
  'memberAreaJourney.period': '效期',
  'memberAreaJourney.no_records': '尚無會員歷程紀錄',
  'memberAreaJourney.reason_1': '新會員',
  'memberAreaJourney.reason_2': '消費升等',
  'memberAreaJourney.reason_3': '手動升等',
  'memberAreaJourney.reason_4': '手動降等',
  'memberAreaJourney.reason_5': '到期降等',
  'memberAreaJourney.reason_6': '到期續等',
  'memberAreaJourney.reason_7': '取消交易降等',
  'memberAreaJourney.reason_8': '到期指定降等',
  'memberAreaJourney.reason_9': '到期指定續等',
  'memberAreaJourney.reason_10': '新版會員機制上線',
} as Record<string, string>;
