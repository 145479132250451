import { ReactComponent as Puzzle } from '../../assets/img_puzzle.svg';
import FormattedMessage from '../FormattedMessage';
import styles from './index.module.scss';

const LoginFailed = () => {
  return (
    <div className={styles.loginFailed}>
      <Puzzle />
      <div className={styles.description}>
        <p>{FormattedMessage('components.LoginFailed.title')}</p>
        <p>{FormattedMessage('components.LoginFailed.notice')}</p>
      </div>
    </div>
  );
};
export default LoginFailed;
