export default {
  'memberAreaJourney.title': '',
  'memberAreaJourney.period': '',
  'memberAreaJourney.no_records': '',
  'memberAreaJourney.reason_1': '',
  'memberAreaJourney.reason_2': '',
  'memberAreaJourney.reason_3': '',
  'memberAreaJourney.reason_4': '',
  'memberAreaJourney.reason_5': '',
  'memberAreaJourney.reason_6': '',
  'memberAreaJourney.reason_7': '',
  'memberAreaJourney.reason_8': '',
  'memberAreaJourney.reason_9': '',
  'memberAreaJourney.reason_10': '',
} as Record<string, string>;
