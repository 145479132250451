export default {
  'register.alert.confirm': '請勾選同意使用條款',
  'register.alert.register_fail': '註冊失敗，請重試',
  'register.alert.phone_number.exists': '這個手機號碼已被使用，請改填其它手機號碼。',
  'register.alert.phone_number.verify_fail': '手機號碼 驗證錯誤，請重新輸入',
  'register.alert.email.exists': '這個 Email 已被使用，請改填其它 Email。',
  'register.alert.email.verify_fail': 'Email 驗證錯誤，請重新輸入',
  'register.alert.birthday.verify_fail': '生日 驗證錯誤，請重新輸入',
  'register.alert.gender.verify_fail': '性別 驗證錯誤，請重新輸入',
  'register.alert.city.verify_fail': '居住地 驗證錯誤，請重新輸入',
  'register.alert.job.verify_fail': '職業 驗證錯誤，請重新輸入',
  'register.alert.education_level.verify_fail': '教育程度 驗證錯誤，請重新輸入',
  'register.alert.marriage.verify_fail': '婚姻狀況 驗證錯誤，請重新輸入',
  'register.alert.interest.verify_fail': '興趣 驗證錯誤，請重新輸入',
  'register.alert.diet.verify_fail': '飲食偏好 驗證錯誤，請重新輸入',
  'register.alert.line_account.registered': '這個 LINE 帳號已註冊過會員。',
  'register.alert.otp.verify_fail': 'OTP 驗證失敗，請重試。',
  'register.alert.field_required': '尚有欄位未填寫完整，請重試。',
  'register.alert.please_select': '請選擇',
  'register.alert.number_required': '請輸入數字',
  'register.alert.number_length': '最多輸入 18 位數，請重新輸入',
  'register.phone_number': '手機號碼',
  'register.phone_number.required': '請輸入手機號碼',
  'register.phone_number.format_error': '手機號碼格式錯誤',
  'register.phone_number.not_match': '兩次手機號碼不符合',
  'register.phone_number.enter_again': '請再次輸入手機號碼',
  'register.name': '姓名',
  'register.name.required': '請輸入姓名',
  'register.name.format_error': '僅能輸入英文字母與中文字',
  'register.birth': '生日',
  'register.birth.required': '請輸入生日',
  'register.email': 'E-mail',
  'register.email.required': '請輸入 E-mail',
  'register.email.format_error': 'E-mail 格式錯誤',
  'register.gender': '性別',
  'register.gender.required': '請選擇性別',
  'register.city': '居住地',
  'register.city.required': '請選擇居住地',
  'register.job': '職業',
  'register.job.required': '請選擇職業',
  'register.eduction_level': '教育程度',
  'register.eduction_level.required': '請選擇教育程度',
  'register.marriage': '婚姻狀況',
  'register.marriage.required': '請選擇婚姻狀況',
  'register.habit': '興趣',
  'register.habit.required': '請選擇興趣',
  'register.diet': '飲食偏好',
  'register.diet.required': '請選擇飲食偏好',
  'register.agree': '我同意',
  'register.term': '使用條款',
  'register.account_verification': '帳號認證',
  'register.member_information': '會員資料',
  'register.complete': '完成註冊',
  'register.submit': '送出',
  'register.success': '註冊成功',
  'register.country_code': '國碼',
  'register.country_code.required': '請選擇國碼',
} as Record<string, string>;
