/**
 * Brand Context
 *
 * 品牌相關設定 (menu, color, name, etc....)
 */
import type { BrandInfo } from 'api';
import { getBrandInfo } from 'api';
import * as PropTypes from 'prop-types';
import type { Consumer, Context, FC } from 'react';
import { createContext, useContext, useEffect, useState } from 'react';

interface BrandProviderProps {
  brandIdentify: string;
}
interface BrandContext {
  error?: any;
  brandInfo?: BrandInfo;
  initializing: boolean;
  retry?: () => Promise<void>;
  loading?: boolean;
}

type CreateBrandContext = () => {
  BrandConsumer: Consumer<BrandContext>;
  BrandProvider: FC<BrandProviderProps>;
  useBrand: () => BrandContext;
};
const BrandProviderPropTypes = {
  children: PropTypes.element.isRequired,
  brandIdentify: PropTypes.string.isRequired,
};

const createBrandProvider = (context: Context<BrandContext>) => {
  const BrandProvider: FC<BrandProviderProps> = ({ children, brandIdentify }) => {
    const [brandState, setBrandState] = useState<{
      error?: unknown;
      initializing: boolean;
      brandInfo?: BrandInfo;
      loading: boolean;
    }>({
      initializing: true,
      loading: false,
    });

    useEffect(() => {
      (async () => {
        if (!brandIdentify) {
          setBrandState((prev) => ({
            ...prev,
            error: true,
            initializing: false,
          }));
          return;
        }
        try {
          const { data: resp, error } = await getBrandInfo(brandIdentify);
          if (error) {
            setBrandState((prev) => ({
              ...prev,
              error,
              initializing: false,
            }));
            return;
          }
          if (resp) {
            setBrandState((prev) => ({
              ...prev,
              brandInfo: resp,
              initializing: false,
            }));

            localStorage.setItem('lang', resp.language);
          }
        } catch (error) {
          console.log(error);
          setBrandState((prev) => ({
            ...prev,
            error,
            initializing: false,
          }));
        }
      })();
    }, [brandIdentify]);

    const retry = async () => {
      try {
        setBrandState((prev) => ({
          ...prev,
          loading: true,
        }));
        const { data: resp, error } = await getBrandInfo(brandIdentify);
        if (error) {
          setBrandState((prev) => ({
            ...prev,
            error,
            loading: false,
          }));
          return;
        }
        if (resp) {
          setBrandState((prev) => ({
            ...prev,
            brandInfo: resp,
            initializing: false,
            loading: false,
            error: false,
          }));
        }
      } catch (error) {
        console.log(error);
        setBrandState((prev) => ({
          ...prev,
          error,
          loading: false,
        }));
      }
    };

    return (
      <context.Provider
        value={{
          retry,
          ...brandState,
        }}
      >
        {children}
      </context.Provider>
    );
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  BrandProvider.propTypes = BrandProviderPropTypes;
  return BrandProvider;
};
export const createBrandContext: CreateBrandContext = () => {
  const context = createContext<BrandContext>({
    brandInfo: undefined,
    initializing: true,
  });
  context.displayName = 'BrandContext';

  return {
    BrandConsumer: context.Consumer,
    BrandProvider: createBrandProvider(context),
    useBrand: () => useContext(context),
  };
};
export const { BrandConsumer, BrandProvider, useBrand } = createBrandContext();
